import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Datepicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment';
import { useForm } from "react-hook-form";
import { setGoalFinalSubmit } from "../const"
import Swal from 'sweetalert2';

const SubmitGoalTimeline = ({ showPopup, closePopup, Data }) => {
    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: 'onChange'
    });
    const [showResults, setShowResults] = useState(false)
    const [resultMessage, setResultMessage] = useState({});
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const onSubmit = (data, e) => {
        // console.log(data, moment(fromDate).format('DD-MMM-YYYY'), moment(toDate).format('DD-MMM-YYYY'));
        setGoalFinalSubmit({ fromDate: moment(fromDate).format('DD-MMM-YYYY'), toDate: moment(toDate).format('DD-MMM-YYYY') }).then(resp => {
            if (resp.status === 200 && resp.data.status.code === 'SUCCESS') {
                Swal.fire({
                    title: "Success",
                    text: resp.data.status.message,
                    icon: "success"
                }).then((result) => {
                    if (result.isConfirmed) {
                        closePopup();
                    }
                })
            }
        })
    }

    return (
        <Modal show={showPopup} onHide={closePopup} size='md'>
            <Modal.Header>
                <Modal.Title>Enter the duration you want to set the goal for</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            <div className='col-md-9'>
                                {showResults ? <Results key={Math.random()} message={resultMessage.message} error={resultMessage.error} /> : null}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className="form-group">
                                    <label htmlFor>From Date</label>
                                    <Datepicker
                                        selected={fromDate}
                                        onChange={date => setFromDate(date)}
                                        placeholderText="Select From Date"
                                        // maxDate={new Date()}
                                        //minDate={new Date(moment(batchSummary.startDateTime).format('YYYY-MM-DD'))}
                                        dateFormat="dd-MMM-yyyy"
                                        name="fromDate"
                                        className="form-control form-control-sm"

                                    >
                                    </Datepicker>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className="form-group">
                                    <label htmlFor>To Date</label>
                                    <Datepicker
                                        selected={toDate}
                                        onChange={date => setToDate(date)}
                                        placeholderText="Select To Date"
                                        // maxDate={new Date()}
                                        minDate={new Date(moment(fromDate).format('YYYY-MM-DD'))}
                                        dateFormat="dd-MMM-yyyy"
                                        name="toDate"
                                        className="form-control form-control-sm"

                                    >
                                    </Datepicker>
                                </div>
                            </div>
                            <div className='col-md-4 mt-4'>
                                <input type="submit" value="Submit" class="btn btn-primary newBtn" />
                                {/* <input type="reset" value="Reset" class="btn btn-secondary newBtn" /> */}
                            </div>
                        </div>

                        <div className='row'>

                        </div>
                    </form>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closePopup}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};


const Results = (props) => {
    let error, classes;
    if (props.error === false) {
        error = 'Success';
        classes = 'alert alert-success alert-dismissible fade show'
    } else {
        error = 'Error!';
        classes = 'alert alert-danger alert-dismissible fade show'
    }
    return (
        <div className="results">
            <div className={classes}>
                <strong>{error}</strong> {props.message}
                <button type="button" class="close" data-dismiss="alert">&times;</button>
            </div>
        </div>
    );
}

export default SubmitGoalTimeline;
