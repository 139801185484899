import React, { useEffect, useState } from 'react';
import DataTable from "react-data-table-component";
import { goalVsActualReport, Rr_Every_Component_Wise_BOQ_List, filterByFinishComponentEmission, filterByOEMs } from "../const";
import moment from 'moment';
import { useForm, Controller } from "react-hook-form";

const GoalActualReport = () => {
    const [offcanvas, setoffcanvas] = useState(false);
    const [datasubComp, setdatasubComp] = useState([]);
    const [data, setData] = useState([]);
    const [oemDropdownValue, setOemDropdownValue] = useState([]);
    const [componentNameDropdownValue, setComponentNameDropdownValue] = useState([]);
    const { register, handleSubmit, getValues, watch, formState: { errors } } = useForm({
        mode: 'onChange'
    });
    const columns = [
        {
            name: 'OEM',
            selector: row => row.OEM,
        },
        {
            name: 'Category Name',
            selector: row => row.categoryName,
            minWidth: "250px"
        },
        {
            name: 'Activity Type',
            selector: row => row.activityType,
            minWidth: "150px"
        },
        {
            name: 'Component Name',
            selector: row => row.componentName,
            minWidth: "250px"

        },
        // {
        //     name: 'BOQ',
        //     selector: row => row.bo,
        //     cell: row => (
        //         <>
        //             <span
        //                 style={{ fontSize: 14, marginLeft: 10, color: "blue", cursor: "pointer" }}
        //                 onClick={() => Overlay(row)} // Make sure to use an arrow function here
        //             >BOQ</span>
        //         </>
        //     ),


        // },
        {
            name: 'Quantity',
            selector: row => row.quantity,
        },
        {
            name: 'Base Line Co2 e (in kg)',
            selector: row => row.baseLineKg,
        },
        {
            name: 'Goal Value (per unit)',
            selector: row => row.setGoalPerUnitInKG,
        },
        {
            name: 'Actual Co2 e (in kg per unit)',
            selector: row => row.actualCo2eEmission,
            cell: row => (
                <>
                    <span
                        style={{ fontSize: 14, marginLeft: 10, color: "blue", cursor: "pointer" }}
                        onClick={() => Overlay(row)} // Make sure to use an arrow function here
                    >{row.actualCo2eEmission}</span>
                </>
            ),
        },
        {
            name: 'From Date',
            selector: row => moment(row.fromDate).format('DD-MMM-YYYY'),
            minWidth: "120px"

        },
        {
            name: 'To Date',
            selector: row => moment(row.toDate).format('DD-MMM-YYYY'),
            minWidth: "120px"

        }
    ];
    const columnsSubcomp = [
        {
            name: 'Supplier Name',
            selector: row => row.supplierName,
            sortable: true
        },
        {
            name: 'Material',
            selector: row => row.materialComponentName,
            sortable: true
        },
        // {
        //     name: 'Base Line qty',
        //     selector: row => row.quantity,
        //     sortable: true
        // },
        {
            name: 'Base Line kg CO₂ e',
            selector: row => row.baseLineKg,
            sortable: true
        },
        {
            name: 'Actual qty',
            selector: row => row.actualQuantity,
            sortable: true
        },
        {
            name: 'Actual kg CO₂ e',
            selector: row => row.actualCo2eEmission,
            sortable: true
        }
    ];

    useEffect(() => {
        let obj = {
            oem: "",
            componentName: ""
        }
        goalVsActualReport(obj).then(res => {
            if (res.status === 200 && res.data.status.code === 'SUCCESS') {
                setData(res.data.primary);
                filterByOEMs().then().then(resOEM => {
                    if (resOEM.status === 200 && resOEM.data.status.code === 'SUCCESS') {
                        setOemDropdownValue(resOEM.data.primary);
                        filterByFinishComponentEmission({ OEM: "" }).then().then(resComponentName => {
                            if (resComponentName.status === 200 && resComponentName.data.status.code === 'SUCCESS') {
                                setComponentNameDropdownValue(resComponentName.data.primary)
                            }
                        })
                    }
                })
            }
        })
    }, [])

    const Overlay = (row) => {
        Rr_Every_Component_Wise_BOQ_List({ componentName: row.componentName }).then(resp => {
            if (resp.status === 200 && resp.data[0].status.code === 'SUCCESS') {
                setdatasubComp(resp.data[0].primary)
            }
        })
        setoffcanvas(true);
    }

    const closeSideOverlay = () => {
        setoffcanvas(false);
    };

    const onSubmitFilter = (e) => {
        // console.log("ComponentName", e);
        let obj = {
            oem: e.oem,
            componentName: e.componentName
        }
        goalVsActualReport(obj).then(res => {
            if (res.status === 200 && res.data.status.code === 'SUCCESS') {
                setData(res.data.primary);
            }
        });

    }

    const onHandleChangeOEM = (e) => {
        filterByFinishComponentEmission({ OEM: e.target.value }).then().then(resComponentName => {
            if (resComponentName.status === 200 && resComponentName.data.status.code === 'SUCCESS') {
                setComponentNameDropdownValue(resComponentName.data.primary)
            }
        })
    }

    return (
        <div className="farmMaster">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark" >Goal vs Actual </h2>
                </div>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <form onSubmit={handleSubmit(onSubmitFilter)}>
                        <div className="row">
                            <div className="col-md-12 mb-0" style={{}}>
                                <div className="row">
                                    <div className="col-md-1" style={{ textAlign: 'left', marginTop: 5 }}><strong>Filter By: </strong></div>
                                </div>
                                <div className="row mb-0">
                                    <div className='col-md-2'>
                                        <div className="form-group">
                                            <select class="form-control form-control-sm" name="oem"
                                                {...register("oem", {
                                                    onChange: (e) => { onHandleChangeOEM(e) }
                                                })}>
                                                <option value="">-Select OEM-</option>
                                                {oemDropdownValue.map((item) => (
                                                    <option value={item.partyId}>{item.partyId}</option>
                                                ))}
                                            </select>

                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="form-group">
                                            <select class="form-control form-control-sm" name="componentName"
                                                {...register("componentName")}>
                                                <option value="">-Select Component Name(Finished Good )-</option>
                                                {componentNameDropdownValue.map((item) => (
                                                    <option value={item.componentName}>{item.componentName}</option>
                                                ))}
                                            </select>

                                        </div>
                                    </div>

                                    <div className="col mb-0">
                                        <button style={{ marginTop: 0 }} className="btn btn-sm btn-primary">Submit</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className="card">
                <div class="card-body">
                    <div className='row'>
                        <div className='col-md-12'>
                            <DataTable
                                columns={columns}
                                data={data}
                                pagination
                                dense
                                selectableRowsHighlight='true'
                                compact
                                highlightOnHover='true'
                                striped
                            />
                        </div>
                    </div>
                </div>
            </div>


            <div id='sideOverlay' className={`side-overlay ${offcanvas ? 'open' : ''}`} style={{ boxShadow: "0 0 7px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 26%)", width: "70%" }}>
                <div className="overlay-content">
                    <i class="fas fa-times-circle close-button" aria-hidden="true" onClick={closeSideOverlay} style={{ fontSize: 20 }}></i>
                    {/* <button className="close-button" >Close</button> */}
                    <div className='row' style={{ marginTop: "2%", marginLeft: "3%", marginRight: "3%" }}>
                        <div className='col-md-12'>
                            <div className='card-body'>
                                <div className='title row' style={{ justifyContent: "left" }}>
                                    <h2 className='p-1 ml-1 m-2 text-dark'>
                                        BOQ
                                    </h2>
                                </div>
                                <div className='row mt-4 sideOverlaySummary'>
                                    <div className='col-6'>
                                        <strong>Sub Assembly Components	: </strong> {datasubComp.length !== 0 && (
                                            datasubComp[0].componentName
                                        )}
                                    </div>
                                </div>
                                <div className='row mt-3' style={{ border: '1px solid black' }}>
                                    <div className='col-12'>
                                        <DataTable
                                            columns={columnsSubcomp}
                                            data={datasubComp}
                                            pagination
                                            dense
                                            selectableRowsHighlight='true'
                                            compact
                                            highlightOnHover='true'
                                            striped
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    )
}
export default GoalActualReport;