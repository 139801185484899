import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
const ActivityInfo = ({ showPopup, popupData, productName, closePopup }) => {
    const columns = [
        {
            name: "Component Name",
            selector: row => row.componentName,
            sortable: true,
        },
        {
            name: "Activity ID",
            selector: row => row.activityId,
            sortable: true,
        }

    ];
    const [data, setData] = useState(popupData);
    return (
        <Modal show={showPopup} onHide={closePopup} size='xl'>
            <Modal.Header>
                <Modal.Title>Product Name - {productName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12">
                        <DataTable
                            columns={columns}
                            data={data}
                            defaultSortField="supplierId"
                            pagination
                            dense
                            selectableRowsHighlight='true'
                            compact
                            highlightOnHover='true'
                            striped
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closePopup}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ActivityInfo;
