import DataTable from "react-data-table-component";
import React, { useState, useEffect, useRef } from "react";
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';
import { useForm, Controller } from "react-hook-form";
import { IoMdAdd, IoMdRemove } from "react-icons/io";
import { createActualValue, categoryInfoUnderSetGoal, Rr_Category_Under_Product_List, Rr_Every_Component_Wise_BOQ_List, getSupplierListForEveryMaterial, setGoalCreate, setGoalPrePopulate, setGoalUpdate } from "../const"
import Swal from 'sweetalert2';
import SubmitGoalTimeline from "./submitGoalTimeline";





const BreakupList = () => {
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm({
        mode: 'onChange'
    });
    const [dataMainGrid, setDataMainGrid] = useState([]);
    const [showResults, setShowResults] = useState(false)
    const [resultMessage, setResultMessage] = useState({});
    const [rows, setRows] = useState([{ id: Date.now() }]);
    const [data, setData] = useState([]);
    const [datasubComp, setdatasubComp] = useState([]);
    const [toalco2eValue, setToalco2eValue] = useState("");
    const [selectedSetGoalMaterialName, setSelectedSetGoalMaterialName] = useState("");
    const [selectedSetGoal, setselectedSetGoal] = useState();
    const [dropdownData, setDropdownData] = useState([]);
    const [submitButtonShow, setSubmitButtonShow] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [DisableQuantityField, setdisableQuantityField] = useState(false)

    const [viewListData, setViewListData] = useState([])
    const [viewSideOverlay, setViewSideOverlay] = useState(false)
    const [edited, setEdited] = useState(false)

    const [inputList, setInputList] = useState([{ supplierID: "", qty: "", actual: "", supplierName: "" }]);

    const formRef = useRef(null);


    const [expandedRow, setExpandedRow] = useState(null);


    useEffect(() => {

        setShowLoader(true);
        // console.log("dataMainGrid", dataMainGrid)
        // console.log("JSON.pars", JSON.parse(sessionStorage.getItem("calculateData")))

        createActualValue({ electronics: JSON.parse(sessionStorage.getItem("calculateData")) }).then(res => {
            if (res.status === 200 && res.data.status.code === 'SUCCESS') {
                // console.log("11111", res)
            }
        });
        categoryInfoUnderSetGoal().then(reslist => {
            // console.log("categoryInfoUnderSetGoal", reslist);
            let updatedArray = reslist.data.primary.map((item, index) => {
                if (index === 0) {
                    // If index is 0, set disabled to true
                    return { ...item, disabled: false };
                } else {
                    // For other indexes, set disabled to false
                    return { ...item, disabled: true };
                }
            })
            setDataMainGrid(updatedArray);
            // console.log("dataMainGrid", dataMainGrid);
            const hasDraftGoal = updatedArray.some(item => item.setGoal === "DRAFT");
            if (hasDraftGoal) {
                setSubmitButtonShow(true);
            }
        });
        // Rr_Category_Under_Product_List({ categoryName: "Purchased goods and services" }).then(subCatResp => {
        //     if (subCatResp.status === 200 && subCatResp.data.status.code === 'SUCCESS') {
        //         setData(subCatResp.data.primary)
        //         // console.log("nowDate", new Date(), "fromDate", new Date(data[0].fromDate), "toDate", new Date(data[0].toDate), "booo", new Date(data[0].fromDate) >= new Date() && new Date() < new Date(data[0].toDate))
        //     }
        setShowLoader(false)
        // })
        // console.log("dataMainGrid", dataMainGrid)


    }, [])

    const onSubmit = (data, e) => {
        const components = [];
        // console.log("data", edited)
        inputList.forEach((row, index) => {
            // Extract relevant data from the form fields
            const supplierID = row.supplierID;
            const actual = row.actual;
            let qty = row.qty;
            // console.log("qty", qty)
            if (qty === '') {
                qty = "0"
            }
            const supplierName = row.supplierName;

            // console.log("000000", supplierID, actual, supplierName)
            // If all required fields are filled, construct the object
            if (supplierID && actual && supplierName) {
                // console.log("22222")
                const actualSupplierList = {
                    supplierId: supplierID,
                    actualCo2EValue: parseFloat(actual),
                    // suppliedQuantity: parseInt(qty),
                    reductionPerActual: parseFloat(supplierName)
                };

                // Push the constructed object into the components array
                components.push(actualSupplierList);
            }
        });

        // console.log("selectedSetGoal", components, selectedSetGoal)
        if (selectedSetGoal.materialName === undefined) {
            selectedSetGoal.materialName = ""
        }
        const finalData = {
            categoryName: selectedSetGoal.scope3category,
            activityType: selectedSetGoal.activityType,
            component: [
                {
                    componentName: selectedSetGoal.materialName,
                    actualSupplierList: components
                }
            ]
        };
        // console.log("finalData", finalData);
        // return
        if (edited) {
            setGoalUpdate(finalData).then(res => {
                if (res.status === 200 && res.data.status.code === 'SUCCESS') {
                    Swal.fire({
                        title: "Success",
                        text: res.data.status.message,
                        icon: "success"
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setInputList([{ supplierID: "", qty: "", actual: "", supplierName: "" }]);
                            e.target.reset();
                            categoryInfoUnderSetGoal().then(reslist => {
                                console.log("categoryInfoUnderSetGoal", reslist);
                                let updatedArray = reslist.data.primary.map((item, index) => {
                                    if (index === 0) {
                                        // If index is 0, set disabled to true
                                        return { ...item, disabled: false };
                                    } else {
                                        // For other indexes, set disabled to false
                                        return { ...item, disabled: true };
                                    }
                                })
                                setDataMainGrid(updatedArray);
                                const hasDraftGoal = updatedArray.some(item => item.setGoal === "DRAFT");
                                if (hasDraftGoal) {
                                    setSubmitButtonShow(true);
                                }
                            });
                            Rr_Category_Under_Product_List({ categoryName: "Purchased goods and services" }).then(subCatResp => {
                                if (subCatResp.status === 200 && subCatResp.data.status.code === 'SUCCESS') {
                                    setData(subCatResp.data.primary)
                                }
                            })

                            setOffcanvasSetGoal(false);
                        }
                    })
                }
                else if (res.data.status.code === 'error') {
                    Swal.fire({
                        text: res.data.status.message,
                        icon: "error"
                    });
                }
            })
        }
        else {
            setGoalCreate(finalData).then(res => {
                if (res.status === 200 && res.data.status.code === 'SUCCESS') {
                    Swal.fire({
                        title: "Success",
                        text: res.data.status.message,
                        icon: "success"
                    }).then((result) => {
                        if (result.isConfirmed) {
                            e.target.reset();
                            setInputList([{ supplierID: "", qty: "", actual: "", supplierName: "" }]);
                            categoryInfoUnderSetGoal().then(reslist => {
                                // console.log("categoryInfoUnderSetGoal", reslist);
                                let updatedArray = reslist.data.primary.map((item, index) => {
                                    if (index === 0) {
                                        // If index is 0, set disabled to true
                                        return { ...item, disabled: false };
                                    } else {
                                        // For other indexes, set disabled to false
                                        return { ...item, disabled: true };
                                    }
                                })
                                setDataMainGrid(updatedArray);
                                const hasDraftGoal = updatedArray.some(item => item.setGoal === "DRAFT");
                                if (hasDraftGoal) {
                                    setSubmitButtonShow(true);
                                }
                            });
                            Rr_Category_Under_Product_List({ categoryName: "Purchased goods and services" }).then(subCatResp => {
                                if (subCatResp.status === 200 && subCatResp.data.status.code === 'SUCCESS') {
                                    setData(subCatResp.data.primary)
                                }
                            })

                            setOffcanvasSetGoal(false);
                        }
                    })
                }
                else if (res.data.status.code === 'error') {
                    Swal.fire({
                        text: res.data.status.message,
                        icon: "error"
                    });
                }
            })
        }
    }



    const handleRest = (e) => {
        formRef.current.reset();
        // e.target.reset();
        setInputList([{ supplierID: "", qty: "", actual: "", supplierName: "" }]);

    }




    const handleAddRow = () => {
        setInputList([...inputList, { supplierID: "", qty: "", actual: "", supplierName: "" }]);
        // console.log("add", inputList)

    };

    const handleRemoveRow = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);

    };


    const handleInputChange = async (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        // console.log("e111", list)
        setInputList(list);
    };

    const [offcanvas, setoffcanvas] = useState(false);
    const [offcanvasSetGoal, setOffcanvasSetGoal] = useState(false);
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="22" color="#333" />,
    });
    const [showLoader, setShowLoader] = useState(false);



    const closeSideOverlay = () => {
        setoffcanvas(false);
        setOffcanvasSetGoal(false);
        setViewSideOverlay(false)
    };

    const columnsViewList = [
        {
            name: 'Supplier Name',
            selector: row => row.supplierId,
            sortable: true
        },
        {
            name: 'Supplied Quantity',
            selector: row => row.suppliedQuantity,
            sortable: true
        },
        {
            name: 'Actual CO₂ e',
            selector: row => row.actualCo2EValue,
            sortable: true
        },
        {
            name: 'CO₂ e (Reduction % on Actual)',
            selector: row => row.reductionPerActual,
            sortable: true
        },
    ]

    const columnsSubcomp = [
        {
            name: 'Supplier Name',
            selector: row => row.supplierName,
            sortable: true
        },
        {
            name: 'Material',
            selector: row => row.materialComponentName,
            sortable: true
        },
        {
            name: 'Base Line kg CO₂ e',
            selector: row => row.baseLineKg,
            sortable: true
        },
        {
            name: 'Actual qty',
            selector: row => row.actualQuantity,
            sortable: true
        },
        {
            name: 'Actual kg CO₂ e',
            selector: row => row.actualCo2eEmission,
            sortable: true
        }
    ];


    const columnsMainGrid = [
        {
            name: 'category Name',
            selector: row => row.scope3category,
            minWidth: "250px"
        },
        {
            name: 'Activity Type',
            selector: row => row.activityType,
            minWidth: "250px"
        },
        {
            name: 'Goal Value (per unit)',
            selector: row => row.setGoalPerUnitAvg,
            minWidth: "250px"
        },
        {
            name: 'Actual CO₂ e (in Kg per unit)',
            selector: row => row.finalActualValue,
            minWidth: "250px"
        },

        {
            name: 'Set Goal',
            selector: row => row.setGoal,
            cell: (row, index) => (
                <>
                    {
                        // row.scope3category === "Purchased goods and services" ? (
                        <span disabled={true}
                            style={{ fontSize: 14, marginLeft: 10, color: "gray", cursor: "not-allowed" }} > {row.setGoal}
                        </span>
                        // ) :
                        // (
                        //     row.setGoal === 'PROCESSED' ? (
                        //         new Date(row.fromDate) >= new Date() && new Date() < new Date(row.toDate) ?
                        //             <>
                        //                 <span
                        //                     style={{ fontSize: 14, marginLeft: 10, color: "blue", cursor: "pointer" }}
                        //                     onClick={() => setGoalOverlay(row, "mainGrid")}>
                        //                     Edit
                        //                 </span>
                        //             </>
                        //             :
                        //             <>
                        //                 <span
                        //                     style={{ fontSize: 14, marginLeft: 10, color: "blue", cursor: "pointer" }}
                        //                     onClick={() => setViewOverlay(row)}>
                        //                     View
                        //                 </span>
                        //             </>
                        //     ) : (
                        //         <>
                        //             <span
                        //                 style={{ fontSize: 14, marginLeft: 10, color: "blue", cursor: "pointer" }}
                        //                 onClick={() => setGoalOverlay(row, "mainGrid")}>
                        //                 {row.setGoal}
                        //             </span>
                        //         </>
                        //     )
                        // )
                    }

                </>
            ),
        }
    ]





    const Overlay = (row) => {
        console.log("row", row.materialName);
        Rr_Every_Component_Wise_BOQ_List({ componentName: row.materialName }).then(resp => {
            if (resp.status === 200 && resp.data[0].status.code === 'SUCCESS') {
                setdatasubComp(resp.data[0].primary)
            }
        })
        setoffcanvas(true);
    }

    const setViewOverlay = (data) => {

        let name;
        if (data.materialName === undefined) {
            name = { "scope3category": data.scope3category }
        }
        else {
            name = { "componentName": data.materialName }

        }

        setGoalPrePopulate(name).then(resData => {
            if (resData.status === 200 && resData.data[0].status.code === 'SUCCESS') {
                let suppliersList = resData.data[0].primary;
                let array = [];
                suppliersList.forEach((list, index) => {
                    list.suppliersList.forEach(item => {
                        array.push(item)
                    })
                });
                console.log("array", array)
                setViewListData(array);
                setViewSideOverlay(true)
            }
        })
    }

    const setGoalOverlay = (data, gridType) => {
        setselectedSetGoal(data);
        if (gridType === 'mainGrid') {
            setdisableQuantityField(true)
        }
        if (gridType === 'subGrid') {
            setdisableQuantityField(false)
        }
        if (data.setGoal === "DRAFT" || data.setGoal === "PROCESSED") {
            // console.log(1111)
            setEdited(true)
        }
        console.log("selectedSetGoal", selectedSetGoal, data)
        setSelectedSetGoalMaterialName(data.materialName)
        setToalco2eValue(data.actualvalue)
        setOffcanvasSetGoal(true);
        if (data.setGoal === "DRAFT" || data.setGoal === "PROCESSED") {
            setShowLoader(true);
            console.log("resDataresDataresDataresData")
            let name;
            if (data.materialName === undefined) {
                name = { "scope3category": data.scope3category }
            }
            else {
                name = { "componentName": data.materialName }

            }

            setGoalPrePopulate(name).then(resData => {
                if (resData.status === 200 && resData.data[0].status.code === 'SUCCESS') {
                    setShowLoader(false);
                    let suppliersList = resData.data[0].primary;
                    let newRows = [];
                    suppliersList.forEach((supplier, outerIndex) => {
                        supplier.suppliersList.forEach((list, innerIndex) => {
                            newRows.push({
                                supplierID: list.supplierId,
                                // qty: list.suppliedQuantity,
                                actual: list.actualCo2EValue,
                                supplierName: list.reductionPerActual
                            });
                            // setValue(`supplierID_${innerIndex}`, list.supplierId);
                            // setValue(`qty_${innerIndex}`, list.suppliedQuantity);
                            // setValue(`actual_${innerIndex}`, list.actualCo2EValue);
                            // setValue(`supplierName_${innerIndex}`, list.reductionPerActual);
                        })

                    })
                    setInputList(newRows);
                    console.log("materialName", data.materialName)
                    getSupplierListForEveryMaterial({ componentName: data.materialName }).then(res => {
                        if (res.status === 200 && res.data.status.code === 'SUCCESS') {
                            setDropdownData(res.data.primary);
                        }
                    })
                }
            })
        }
        else {
            console.log("materialName", data.materialName)
            setInputList([{ supplierID: "", qty: "", actual: "", supplierName: "" }]);
            getSupplierListForEveryMaterial({ componentName: data.materialName }).then(res => {
                if (res.status === 200 && res.data.status.code === 'SUCCESS') {
                    setDropdownData(res.data.primary);
                }
            })
        }


    }

    const handleRowExpand = (row) => {
        // If the row is being expanded, set the expanded row data
        console.log("222", row)
        setExpandedRow(row === expandedRow ? null : row);
    }


    const RenderExpandedRow = ({ data }) => {

        const [subData, setSubData] = useState([]);
        const [showLoader, setShowLoader] = useState(true);

        useEffect(() => {
            const fetchData = async () => {
                const subCatResp = await Rr_Category_Under_Product_List({ categoryName: data.scope3category });
                if (subCatResp.status === 200 && subCatResp.data.status.code === 'SUCCESS') {
                    setSubData(subCatResp.data.primary);
                }
                setShowLoader(false);
            };

            fetchData();
        }, [data.scope3category]);

        let columns = [];

        if (data.scope3category === 'Purchased goods and services') {
            columns = [
                {
                    name: 'Sub Assembly Components',
                    selector: row => row.materialName,
                    minWidth: "250px"
                },
                {
                    name: 'Base Line Co2 e (in kg)',
                    selector: row => row.idealValue,
                },
                // {
                //     name: 'Supplied Quantity',
                //     selector: row => row.totalQuantity,
                // },
                // {
                //     name: 'Actual Co2 e (in kg)',
                //     selector: row => row.actualvalue,
                // },

                {
                    name: 'Goal Value (per unit)',
                    selector: row => row.setGoalPerUnitInKG,
                },
                {
                    name: 'Actual Co2 e (in kg per unit) ',
                    selector: row => row.actualCo2PerKg,
                },
                {
                    name: 'Accuracy (in %)',
                    selector: row => row.accuracy,
                },
                {
                    name: 'Goal vs Actual (in %)',
                    selector: row => row.difference,
                    cell: row => (
                        <>
                            <span className="ml-4">{row.difference}</span>
                            {
                                row.status === "decrease" &&
                                (<img className="mb-1 ml-2" style={{ height: "18px" }} src="/dist/img/decrement.png" />)
                            }
                            {
                                row.status === "increate" &&
                                (<img className="mb-1 ml-2" style={{ height: "18px" }} src="/dist/img/increment.png" />)
                            }
                        </>
                    )
                },
                {
                    name: 'BOQ',
                    selector: row => row.bo,
                    cell: row => (
                        <>
                            <span
                                style={{ fontSize: 14, marginLeft: 10, color: "blue", cursor: "pointer" }}
                                onClick={() => Overlay(row)} // Make sure to use an arrow function here
                            >BOQ</span>
                        </>
                    ),


                },
                {
                    name: 'Set Goal',
                    selector: row => row.setGoal,
                    cell: row => (
                        <>
                            {
                                row.setGoal === 'PROCESSED' ? (
                                    new Date(row.fromDate) >= new Date() && new Date() < new Date(row.toDate) ?
                                        <>
                                            <span
                                                style={{ fontSize: 14, marginLeft: 10, color: "blue", cursor: "pointer" }}
                                                onClick={() => setGoalOverlay(row, "subGrid")} // Make sure to use an arrow function here
                                            >
                                                Edit
                                            </span>
                                        </>
                                        :
                                        <>
                                            <span
                                                style={{ fontSize: 14, marginLeft: 10, color: "blue", cursor: "pointer" }}
                                                onClick={() => setViewOverlay(row)}>
                                                View
                                            </span>
                                        </>
                                ) : (
                                    <>
                                        <span
                                            style={{ fontSize: 14, marginLeft: 10, color: "blue", cursor: "pointer" }}
                                            onClick={() => setGoalOverlay(row, "subGrid")} // Make sure to use an arrow function here
                                        >
                                            {row.setGoal}
                                        </span>
                                    </>
                                )
                            }
                        </>
                    ),
                }
            ];
        }
        else {
            columns = [
                {
                    name: 'Sub Activities',
                    selector: row => row.materialName,
                    minWidth: "250px"
                },

                {
                    name: 'Goal Value (per unit)',
                    selector: row => row.setGoalPerUnitInKG,
                },
                {
                    name: 'Actual Co2 e (in kg per unit) ',
                    selector: row => row.actualCo2PerKg,
                },
                {
                    name: 'Accuracy (in %)',
                    selector: row => row.accuracy,
                },
                {
                    name: 'Goal vs Actual (in %)',
                    selector: row => row.difference,
                    cell: row => (
                        <>
                            <span className="ml-4">{row.difference}</span>
                            {
                                row.status === "decrease" &&
                                (<img className="mb-1 ml-2" style={{ height: "18px" }} src="/dist/img/decrement.png" />)
                            }
                            {
                                row.status === "increate" &&
                                (<img className="mb-1 ml-2" style={{ height: "18px" }} src="/dist/img/increment.png" />)
                            }
                        </>
                    )
                },
                {
                    name: 'Set Goal',
                    selector: row => row.setGoal,
                    cell: row => (
                        <>
                            {
                                row.setGoal === 'PROCESSED' ? (
                                    new Date(row.fromDate) >= new Date() && new Date() < new Date(row.toDate) ?
                                        <>
                                            <span
                                                style={{ fontSize: 14, marginLeft: 10, color: "blue", cursor: "pointer" }}
                                                onClick={() => setGoalOverlay(row, "subGrid")} // Make sure to use an arrow function here
                                            >
                                                Edit
                                            </span>
                                        </>
                                        :
                                        <>
                                            <span
                                                style={{ fontSize: 14, marginLeft: 10, color: "blue", cursor: "pointer" }}
                                                onClick={() => setViewOverlay(row)}>
                                                View
                                            </span>
                                        </>
                                ) : (
                                    <>
                                        <span
                                            style={{ fontSize: 14, marginLeft: 10, color: "blue", cursor: "pointer" }}
                                            onClick={() => setGoalOverlay(row, "subGrid")} // Make sure to use an arrow function here
                                        >
                                            {row.setGoal}
                                        </span>
                                    </>
                                )
                            }
                        </>
                    ),
                }
            ];
        }



        return (
            <div className='row mt-3 p-4'>
                <div className='col-12'>
                    {showLoader ? (
                        <div>Loading...</div>
                    ) : (
                        <DataTable
                            columns={columns}
                            data={subData}
                            pagination
                            dense
                            selectableRowsHighlight
                            compact
                            highlightOnHover
                            striped
                        />
                    )}
                </div>
            </div>
        );
    };



    const SubmitDraft = (row) => {
        setShowPopup(true)
    }


    const closesubmitDraftPopup = () => {
        setShowPopup(false)

        categoryInfoUnderSetGoal().then(reslist => {
            // console.log("categoryInfoUnderSetGoal", reslist);
            let updatedArray = reslist.data.primary.map((item, index) => {
                if (index === 0) {
                    // If index is 0, set disabled to true
                    return { ...item, disabled: false };
                } else {
                    // For other indexes, set disabled to false
                    return { ...item, disabled: true };
                }
            })
            setDataMainGrid(updatedArray);
            // console.log("dataMainGrid", dataMainGrid);
            const hasDraftGoal = updatedArray.some(item => item.setGoal === "DRAFT");
            console.log("hasDraftGoal", hasDraftGoal)
            if (hasDraftGoal) {
                setSubmitButtonShow(true);
            }
            else {
                setSubmitButtonShow(false);
            }
        });

    }

    return (
        <>
            <div className="farmMaster">
                <div class="row mb-2">
                    <div class="col-sm-1">
                        <h2 class="m-0 text-dark mt-2" style={{ whiteSpace: "nowrap" }}>Set Goal
                            {
                                showLoader ?
                                    <section {...containerProps
                                    } style={{ "margin-top": "0px", marginLeft: "15px", display: 'inline' }}>
                                        {indicatorEl} {/* renders only while loading */}
                                    </section > : ""
                            }
                        </h2>
                    </div>
                    <div class="col-sm-8">
                        {
                            dataMainGrid.length !== 0 &&
                            <>
                                <div className='workHeader'>

                                    <div className='row'>

                                        <div className='col-md-4'>
                                            <h2><strong>Industry:</strong> {dataMainGrid[0].industryName}</h2>
                                        </div>
                                        <div className='col-md-4'>
                                            <h2><strong>Finished Good: </strong>   {dataMainGrid[0].productName}</h2>
                                        </div>
                                        {/* <div className='col-md-3'>
                                            <div className='status'>
                                                <h2> <strong>Manufactured Units: </strong>  {dataMainGrid[0].manufacturingUnit}</h2>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    {
                        submitButtonShow &&
                        <div class="col-sm-3" style={{ justifyContent: "end", display: "flex" }}>
                            <button onClick={SubmitDraft} style={{ marginTop: 0 }} className="btn btn-sm btn-primary">Submit Draft</button>
                        </div>
                    }

                </div>

                <div className="card mt-3">
                    <div class="card-body">
                        <div className='row'>
                            <div className='col-md-12'>
                                <DataTable
                                    columns={columnsMainGrid}
                                    data={dataMainGrid}
                                    pagination
                                    paginationPerPage="15"
                                    dense
                                    selectableRowsHighlight='true'
                                    compact
                                    highlightOnHover='true'
                                    striped
                                    expandableRows
                                    expandableRowsComponent={RenderExpandedRow}
                                    // expandableRowExpanded={(row) => row.expandable}
                                    onRowExpandToggled={(expand, row) => handleRowExpand(row)}
                                    expandableRowExpanded={(row) => row === expandedRow}
                                // expandableRowDisabled={(row, index) => row.disabled}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div id='sideOverlay' className={`side-overlay ${offcanvas ? 'open' : ''}`} style={{ boxShadow: "0 0 7px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 26%)", width: "70%" }}>
                    <div className="overlay-content">
                        <i class="fas fa-times-circle close-button" aria-hidden="true" onClick={closeSideOverlay} style={{ fontSize: 20 }}></i>
                        {/* <button className="close-button" >Close</button> */}
                        <div className='row' style={{ marginTop: "2%", marginLeft: "3%", marginRight: "3%" }}>
                            <div className='col-md-12'>
                                <div className='card-body'>
                                    <div className='title row' style={{ justifyContent: "left" }}>
                                        <h2 className='p-1 ml-1 m-2 text-dark'>
                                            BOQ
                                        </h2>
                                    </div>
                                    <div className='row mt-4 sideOverlaySummary'>
                                        <div className='col-4'>
                                            <strong>Sub Assembly Components	: </strong> {datasubComp.length !== 0 && (
                                                datasubComp[0].componentName
                                            )}
                                        </div>
                                    </div>
                                    <div className='row mt-3' style={{ border: '1px solid black' }}>
                                        <div className='col-12'>
                                            <DataTable
                                                columns={columnsSubcomp}
                                                data={datasubComp}
                                                pagination
                                                dense
                                                selectableRowsHighlight='true'
                                                compact
                                                highlightOnHover='true'
                                                striped
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div id='sideOverlay' className={`side-overlay ${viewSideOverlay ? 'open' : ''}`} style={{ boxShadow: "0 0 7px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 26%)", width: "70%" }}>
                    <div className="overlay-content">
                        <i class="fas fa-times-circle close-button" aria-hidden="true" onClick={closeSideOverlay} style={{ fontSize: 20 }}></i>
                        {/* <button className="close-button" >Close</button> */}
                        <div className='row' style={{ marginTop: "2%", marginLeft: "3%", marginRight: "3%" }}>
                            <div className='col-md-12'>
                                <div className='card-body'>
                                    <div className='title row' style={{ justifyContent: "left" }}>
                                        <h2 className='p-1 ml-1 m-2 text-dark'>
                                            Details
                                        </h2>
                                    </div>
                                    <div className='row mt-4 sideOverlaySummary'>
                                        {/* <div className='col-4'>
                                            <strong>Sub Assembly Components	: </strong> {datasubComp.length !== 0 && (
                                                datasubComp[0].componentName
                                            )}
                                        </div> */}
                                    </div>
                                    <div className='row mt-3' style={{ border: '1px solid black' }}>
                                        <div className='col-12'>
                                            <DataTable
                                                columns={columnsViewList}
                                                data={viewListData}
                                                pagination
                                                dense
                                                selectableRowsHighlight='true'
                                                compact
                                                highlightOnHover='true'
                                                striped
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                <div id='sideOverlay' className={`side-overlay ${offcanvasSetGoal ? 'open' : ''}`} style={{ boxShadow: "0 0 7px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 26%)", width: "70%" }}>
                    <div className="overlay-content">
                        <i class="fas fa-times-circle close-button" aria-hidden="true" onClick={closeSideOverlay} style={{ fontSize: 20 }}></i>
                        {/* <button className="close-button" >Close</button> */}
                        <div className='row' style={{ marginTop: "2%", marginLeft: "3%", marginRight: "3%" }}>
                            <div className='col-md-12'>
                                <div className='card-body'>
                                    <div className='title row' style={{ justifyContent: "left" }}>
                                        <div className="col-4">
                                            <h2 className='p-1 ml-1 m-2 text-dark'>
                                                Set Goal
                                                {/* Total CO₂ e - {toalco2eValue} */}
                                            </h2>
                                        </div>
                                        <div className="col-8">
                                            <h2 className='p-1  m-2 text-dark'>
                                                {selectedSetGoalMaterialName}
                                            </h2>
                                        </div>
                                    </div>
                                    <div className='row mt-4 sideOverlaySummary'>
                                        <div className='col-4'>
                                            {/* <strong>PO #: </strong> {poNumberValue} */}
                                        </div>
                                    </div>
                                    <div className='row mt-1'>
                                        <div className='col-12'>
                                            <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                                                <div className='row'>
                                                    <div className='col-md-9'>
                                                        {showResults ? <Results key={Math.random()} message={resultMessage.message} error={resultMessage.error} /> : null}
                                                    </div>
                                                </div>
                                                {/* <div className='row'> */}

                                                {inputList.map((row, index) => (
                                                    <div className='col-md-12'>
                                                        <div className='row m-3' key={index}>
                                                            <div className='col-md-2'>
                                                                <div className="form-group">
                                                                    <label>Supplier ID</label>
                                                                    <select
                                                                        className='form-control form-control-s'
                                                                        name="supplierID"
                                                                        placeholder="Select"
                                                                        value={row.supplierID}
                                                                        onChange={(e) => handleInputChange(e, index)}
                                                                    >
                                                                        <option value="">-Select-</option>
                                                                        {dropdownData.map(item => (
                                                                            <option
                                                                                key={item.supplierId}
                                                                                value={item.supplierId}
                                                                            >
                                                                                {item.supplierId} - {item.supplierName}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            {/* <div className='col-md-2'>
                                                                <div className="form-group">
                                                                    <label>Supplied Quantity</label>
                                                                    <input
                                                                        disabled={DisableQuantityField}
                                                                        type="text"
                                                                        name="qty"
                                                                        className="form-control form-control-sm"
                                                                        placeholder="Enter Qty"
                                                                        value={row.qty}
                                                                        onChange={(e) => handleInputChange(e, index)}

                                                                    />
                                                                </div>
                                                            </div> */}
                                                            <div className='col-md-3'>
                                                                <div className="form-group">
                                                                    {
                                                                        DisableQuantityField ? (
                                                                            <label>Actual CO₂ e</label>
                                                                        ) : (<label>Actual CO₂ e (In kg per unit)</label>)
                                                                    }

                                                                    <input
                                                                        type="text"
                                                                        className="form-control form-control-sm"
                                                                        placeholder="Enter Actual CO₂ e"
                                                                        name="actual"
                                                                        value={row.actual}
                                                                        onChange={(e) => handleInputChange(e, index)}

                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className='col-md-4'>
                                                                <div className="form-group">
                                                                    <label>CO₂ e (Reduction % on Actual) </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control form-control-sm"
                                                                        placeholder="Reduction % on Actual CO₂ e"
                                                                        name="supplierName"
                                                                        value={row.supplierName}
                                                                        onChange={(e) => handleInputChange(e, index)}
                                                                    // name={`supplierName_${index}`}
                                                                    // {...register(`supplierName_${index}`, {
                                                                    //     required: "Please enter Carbon Emission",
                                                                    // })}
                                                                    />
                                                                    {/* {errors[`supplierName_${index}`] && <span className="err-msg">{errors[`supplierName_${index}`].message}</span>} */}
                                                                </div>
                                                            </div>
                                                            {inputList.length - 1 === index && (

                                                                <div className='col-md-1 mt-4' id="addMore" style={{ whiteSpace: 'nowrap' }}>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-block btn-outline-success btn-xs"
                                                                        onClick={handleAddRow}
                                                                        style={{ width: "65px", marginLeft: "-20%", whiteSpace: "nowrap" }}
                                                                    >
                                                                        <IoMdAdd /> Add
                                                                    </button>
                                                                </div>
                                                            )}
                                                            {inputList.length !== 1 && (

                                                                <div className='col-md-1 mt-4' id="addMore" style={{ whiteSpace: 'nowrap' }}>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-block btn-outline-danger btn-xs"
                                                                        onClick={() => handleRemoveRow(index)}
                                                                        style={{ width: "90px", whiteSpace: "nowrap" }}
                                                                    >
                                                                        <IoMdRemove /> Remove
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                                {/* </div> */}
                                                {/* </div> */}
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <input type="submit" value="Submit" class="btn btn-primary newBtn" />
                                                        <input type="reset" onClick={() => handleRest()} value="Reset" class="btn btn-secondary newBtn" />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >


                <SubmitGoalTimeline key={Math.random()}
                    showPopup={showPopup}
                    supplierData={""}
                    closePopup={closesubmitDraftPopup} >
                </SubmitGoalTimeline>



            </div >

        </>
    )
}

const Results = (props) => {
    let error, classes;
    if (props.error === false) {
        error = 'Success';
        classes = 'alert alert-success alert-dismissible fade show'
    } else {
        error = 'Error!';
        classes = 'alert alert-danger alert-dismissible fade show'
    }
    return (
        <div className="results">
            <div className={classes}>
                <strong>{error}</strong> {props.message}
                <button type="button" class="close" data-dismiss="alert">&times;</button>
            </div>
        </div>
    );
}

export default BreakupList;