import DataTable from "react-data-table-component";
import React, { useState, useEffect } from 'react';
import { getMaterialList } from "../const";
import SupplierInfo from './supplierInfo';
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';

const MaterialMasterList = () => {

    const columns = [
        {
            name: "Material ID",
            selector: row => row.materialId,
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "Material Category",
            selector: row => row.materialCategory,
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "Material Name",
            selector: row => row.materialName,
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "Material Description",
            selector: row => row.materialDescription,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Scope3 Category",
            selector: row => row.scope3category,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Activity Type",
            selector: row => row.activityType,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Supplier Info",
            selector: "",
            minWidth: '150px',
            cell: row => (
                <>
                    <span
                        style={{ fontSize: 14, marginLeft: 10, color: "blue", cursor: "pointer" }}
                        onClick={() => popup(row)}  // Make sure to use an arrow function here
                        id={row.materialId}
                    >View</span>
                </>
            ),
        }
    ];

    const [data, setData] = useState([]);
    const [showMaterialInfoPopup, setShowMaterialInfoPopup] = useState(false);
    const [selectedMaterialID, setSelectedMaterialID] = useState('')
    const [materialDataSet, setMaterialDataSet] = useState([]);
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="22" color="#333" />,
    });
    const [showLoader, setShowLoader] = useState(false);

    const popup = (data) => {
        // console.log("ccccc", data, data.suppliers)
        setSelectedMaterialID(data.materialId)
        setMaterialDataSet(data.suppliers)
        setShowMaterialInfoPopup(true)
    }

    useEffect(() => {
        setShowLoader(true);
        getMaterialList().then(res => {
            if (res.status === 200 && res.data.status.code === 'SUCCESS') {
                setData(res.data.primary);
                setShowLoader(false);
            }
        })
    }, [])


    return (
        <>
            <DataTable
                columns={columns}
                data={data}
                pagination
                dense
                selectableRowsHighlight='true'
                compact
                highlightOnHover='true'
            />
            <SupplierInfo key={Math.random()}
                showPopup={showMaterialInfoPopup}
                materialID={selectedMaterialID}
                supplierData={materialDataSet}
                closePopup={() => { setShowMaterialInfoPopup(false) }}
            ></SupplierInfo>
            {
                showLoader ?
                    <section {...containerProps
                    } style={{ "margin-top": "0px", display: 'inline' }}>
                        {indicatorEl}
                    </section > : ""
            }
        </>
    )

}

export default MaterialMasterList;