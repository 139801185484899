import logo from './logo.svg';
import './App.css';
import TopHeader from "./components/layout/TopHeader";
import TopFooter from "./components/layout/TopFooter";
import Industries from "./components/IndustryDashboard/Industries";
import Login from "./components/layout/Login";
import BreakupList from "./components/BreakUp/list";
import MaterialMaster from "./components/masters/MaterialMaster";
import Supplier from "./components/masters/supplier";
import ActualCO2eReport from "./components/Supplier/ActualCO2eReport";
import Uploads from "./components/Supplier/uploads";
import ComponentBOQReport from "./components/Supplier/ComponentBOQReport";
import GoalActualReport from "./components/Supplier/GoalActualReport"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Calculator from "./components/IndustryDashboard/Calculator"
import Home from './components/Home/home';
import PreCheckDetails from "./components/IndustryDashboard/preCheckDetails";
import LandingScreen from "./components/IndustryDashboard/landingScreen";
import ActivityIdentification from "./components/ActivityIdentification";
import OEMBOQ from "./components/masters/OEMBOQ"
function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/" component={LandingScreen}></Route>
          <Route exact path="/login" component={Login}></Route>
          <Route exact path="/calculator" component={Calculator}></Route>
          <Route exact path="/industries" component={Industries}></Route>
          <Route exact path="/preCheckDetails" component={PreCheckDetails}></Route>
          <div class="wrapper">
            <TopHeader />
            <div className="content myappcontent">
              <div className="container-fluid">
                <Route exact path="/breaKUpList" component={BreakupList}></Route>
                <Route exact path="/home" component={Home}></Route>
                <Route exact path="/materialMaster" component={MaterialMaster}></Route>
                <Route exact path="/supplierMaster" component={Supplier}></Route>
                {/* <Route exact path="/supplierMaster" component={SupplierMaster}></Route> */}
                <Route exact path="/actualCO2eReport" component={ActualCO2eReport}></Route>
                <Route exact path="/uploads" component={Uploads}></Route>
                <Route exact path="/componentBOQReport" component={ComponentBOQReport}></Route>
                <Route exact path="/goalActualReport" component={GoalActualReport}></Route>

                <Route exact path="/activityIdentification" component={ActivityIdentification}></Route>
                <Route exact path="/OEMBOQ" component={OEMBOQ}></Route>



              </div>
            </div>
            <TopFooter />
          </div>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
