import React, { useState, useEffect } from 'react';
import PieChart from './pieChart';
import SankeyChart from "./sankeyChart"
import { getCo2EmissionUnderComposition, listOfAllCategoriesInOpenLCA } from "../const";
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';
import { get } from 'jquery';

const Composition = ({ showPopup, taskID, usersData, closePopup, enteredManufacturedUnit }) => {

    const [items, setItems] = useState([])
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeID, setActiveID] = useState("");
    const [mainCmp, setMainCmp] = useState("");
    const [showSankey, setShowSankey] = useState(false)
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="22" color="#333" />,
    })
    const [showLoader, setShowLoader] = useState(false);
    const [selectedDropdownValue, setSelectedDropdownValue] = useState();
    const [dropdownOptions, setDropDropdownOptions] = useState([]);
    const handleItemClick = (index, id) => {
        if (showSankey === true) {
            setShowSankey(false)
        }
        setActiveIndex(index);
        setActiveID(id);
    };

    useEffect(() => {
        setShowLoader(true);
        setShowSankey(false)
        getListOfAllCategoriesInOpenLCA()

    }, [])


    const getListOfAllCategoriesInOpenLCA = () => {
        listOfAllCategoriesInOpenLCA().then(resp => {
            if (resp.status === 200 && resp.data.status.code === 'SUCCESS') {
                setDropDropdownOptions(resp.data.primary)
                getCo2EmissionUnderCompositionCall(resp.data.primary[0].openLCACategoryName);
                setSelectedDropdownValue(resp.data.primary[0].openLCACategoryName)
            }
        })
    }


    const getCo2EmissionUnderCompositionCall = (selected) => {
        getCo2EmissionUnderComposition({ consumptionType: selected }).then(resp => {
            if (resp.status === 200 && resp.data.status.code === 'SUCCESS') {
                // console.log("111", resp.data.primary)
                setItems(resp.data.primary);
                setActiveID(resp.data.primary[0].componentName);
                setActiveIndex(0);
                setMainCmp(resp.data.primary[0].componentName);
                setShowLoader(false);
            }
        });
    }

    const sankey = () => {
        setShowSankey(true)
    }

    const pieclick = () => {
        setShowSankey(false)
    }


    const handleDataFromChild = (data) => {
        console.log("parentData", data)
        if (showSankey === true) {
            setShowSankey(false)
        }
        const index = items.findIndex(item => item.componentName === data.name);
        setActiveIndex(index);
        setActiveID(data.name);
    }

    const formatNumber = (number) => {
        return parseFloat(number).toLocaleString('en', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };

    const handelSelectionChanged = (e) => {
        setSelectedDropdownValue(e.target.value)
        console.log("data", e.target.value)
        getCo2EmissionUnderCompositionCall(e.target.value)
    }

    return (
        <>
            {
                showLoader ?
                    <section {...containerProps
                    } style={{ "margin-top": "10%", display: 'inline' }}>
                        {indicatorEl}
                    </section > : ""
            }
            <div className='row'>
                <div className='col-md-3 sideBorder'>
                    <div className="row">
                        <div className="col" style={{ textAlign: 'left', marginTop: 5 }}><strong>Impact Category: </strong></div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <div className="form-group">
                                <select class="form-control form-control-sm" name="farmer" onChange={(e) => { handelSelectionChanged(e) }}>
                                    {
                                        dropdownOptions.map((item) => (
                                            <option value={item.openLCACategoryName}>{item.openLCACategoryName}</option>
                                        ))
                                    }
                                </select>

                            </div>
                        </div>
                    </div>
                    <ul className='sideNav' style={{ minHeight: '600px' }}>
                        {items.map((item, index) => (
                            <li
                                key={item.componentName}
                                className={`${index === activeIndex ? 'workItemActive' : ''}
                                ${index > 0 ? 'marginLeft5' : '' // Apply marginLeft5 class starting from index 1
                                    }`}
                                onClick={() => handleItemClick(index, item.componentName)}
                            >
                                {item.componentName} - {formatNumber(item.componentValue.toFixed(2))} {item.refUnit}
                                {
                                    item.percentage && <> ( {item.percentage} %) </>
                                }

                                {/* <span className={`circle ${item.color}Indicator`}></span> */}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='col-md-9'>
                    <div className='workHeader'>
                        <div className='row'>
                            <div className='col-6'>
                                {activeID}
                            </div>
                            <div className='col-6' style={{ display: "flex", justifyContent: "end" }}>
                                {
                                    showSankey ?
                                        <a href='#' style={{ fontWeight: "bold", fontSize: "15px" }} onClick={() => { pieclick(activeID) }}> {"<--"}</a>
                                        :
                                        <a href='#' onClick={() => { sankey(activeID) }}> view Sankey</a>

                                }
                            </div>
                        </div>
                    </div>
                    <div className='taskView' style={{ marginTop: '30px' }}>
                        <div className='row'>
                            <div className='col-md-12'>
                                {
                                    showSankey ?
                                        <SankeyChart id={activeID} mainComp={mainCmp} enteredManufacturedUnit={enteredManufacturedUnit} selectedDropdownValue={selectedDropdownValue} />
                                        :
                                        <PieChart id={activeID} mainComp={mainCmp} enteredManufacturedUnit={enteredManufacturedUnit} sendDataToParent={handleDataFromChild} selectedDropdownValue={selectedDropdownValue} />

                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Composition;