import React, { useState, useEffect } from 'react';
import DataTable from "react-data-table-component";
import { Rr_BOQ_List_Under_Supplier, filterByOEMs, filterByFinishedComponents } from "../const"
import { useForm, Controller } from "react-hook-form";

const ComponentBOQReport = () => {
    const columns = [
        {
            name: 'OEM',
            selector: row => row.oem,
        },
        {
            name: 'Component Name(Finished Good )',
            selector: row => row.componentName,

        },
        {
            name: 'Component Name(Raw Material)',
            selector: row => row.componentMaterialName,
        },
        {
            name: 'Quantity',
            selector: row => row.quantity + " " + row.unit,
        },
        {
            name: 'Version Number',
            selector: row => row.versionNumber,
        },
    ];
    const [data, setData] = useState([]);
    const [oemDropdownValue, setOemDropdownValue] = useState([]);
    const [componentNameDropdownValue, setComponentNameDropdownValue] = useState([]);
    const { register, handleSubmit, getValues, watch, formState: { errors } } = useForm({
        mode: 'onChange'
    });
    useEffect(() => {
        Rr_BOQ_List_Under_Supplier(undefined).then(res => {
            if (res.status === 200 && res.data.status.code === 'SUCCESS') {
                setData(res.data.primary)
                filterByOEMs().then().then(resOEM => {
                    if (resOEM.status === 200 && resOEM.data.status.code === 'SUCCESS') {
                        setOemDropdownValue(resOEM.data.primary);
                        filterByFinishedComponents({ OEM: "" }).then().then(resComponentName => {
                            if (resComponentName.status === 200 && resComponentName.data.status.code === 'SUCCESS') {
                                setComponentNameDropdownValue(resComponentName.data.primary)
                            }
                        })
                    }
                })
            }
        })
    }, [])


    const onSubmitFilter = (e) => {
        // console.log("ComponentName", e);
        let obj = {
            oem: e.oem,
            componentName: e.componentName
        }
        Rr_BOQ_List_Under_Supplier(obj).then(res => {
            if (res.status === 200 && res.data.status.code === 'SUCCESS') {
                setData(res.data.primary);
            }
        });

    }
    const onHandleChangeOEM = (e) => {
        filterByFinishedComponents({ OEM: e.target.value }).then().then(resComponentName => {
            if (resComponentName.status === 200 && resComponentName.data.status.code === 'SUCCESS') {
                setComponentNameDropdownValue(resComponentName.data.primary)
            }
        })
    }

    return (
        <div className="farmMaster">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark" >Component BOQ</h2>
                </div>
            </div>

            <div className='card'>
                <div className='card-body'>
                    <form onSubmit={handleSubmit(onSubmitFilter)}>
                        <div className="row">
                            <div className="col-md-12 mb-0" style={{}}>
                                <div className="row">
                                    <div className="col-md-1" style={{ textAlign: 'left', marginTop: 5 }}><strong>Filter By: </strong></div>
                                </div>
                                <div className="row mb-0">
                                    <div className='col-md-2'>
                                        <div className="form-group">
                                            <select class="form-control form-control-sm" name="oem"
                                                {...register("oem", {
                                                    onChange: (e) => { onHandleChangeOEM(e) }
                                                })}>
                                                <option value="">-Select OEM-</option>
                                                {oemDropdownValue.map((item) => (
                                                    <option value={item.partyId}>{item.partyId}</option>
                                                ))}
                                            </select>

                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="form-group">
                                            <select class="form-control form-control-sm" name="componentName"
                                                {...register("componentName")}>
                                                <option value="">-Select Component Name(Finished Good )-</option>
                                                {componentNameDropdownValue.map((item) => (
                                                    <option value={item.componentName}>{item.componentName}</option>
                                                ))}
                                            </select>

                                        </div>
                                    </div>

                                    <div className="col mb-0">
                                        <button style={{ marginTop: 0 }} className="btn btn-sm btn-primary">Submit</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className="card">
                <div class="card-body">
                    <div className='row'>
                        <div className='col-md-12'>
                            <DataTable
                                columns={columns}
                                data={data}
                                pagination
                                dense
                                selectableRowsHighlight='true'
                                compact
                                highlightOnHover='true'
                                striped
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ComponentBOQReport;