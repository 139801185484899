import DataTable from "react-data-table-component";
import React, { useState, useEffect } from 'react';
import { getSupplierListUnderOEM } from "../const";
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';

const SupplierMasterList = ({ handleEdit }) => {
    const columns = [
        {
            name: "Supplier ID",
            selector: row => row.supplierId,
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "Supplier Name",
            selector: row => row.supplierName,
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "Admin Mail ID",
            selector: row => row.adminMailId,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Admin Contact",
            selector: row => row.adminContact,
            sortable: true,
            minWidth: '60px'
        },
        {
            name: "Address",
            selector: row => row.address,
            sortable: true,
            minWidth: '50px'
        },
        {
            name: "Action",
            // selector: "",
            sortable: true,
            minWidth: '150px',
            cell: row => (
                <>
                    <div>
                        <button className="btn btn-primary m-1" onClick={() => handleEditbutton(row)}>Edit</button>
                        <button className="btn btn-danger m-1" onClick={() => handleDelete(row)}>Delete</button>
                    </div>
                </>
            ),
        }
    ];

    const [data, setData] = useState([]);

    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="22" color="#333" />,
    });
    const [showLoader, setShowLoader] = useState(false);



    const handleEditbutton = (row) => {
        // console.log("Editing supplier with ID:", row.supplierId);
        handleEdit(row.supplierId); // Pass supplierId to parent component


    }

    const handleDelete = (row) => {

    }


    useEffect(() => {
        setShowLoader(true);
        getSupplierListUnderOEM().then(res => {
            if (res.status === 200 && res.data.status.code === 'SUCCESS') {
                setData(res.data.primary);
                setShowLoader(false);
            }
        })
    }, [])

    return (
        <>
            <DataTable
                columns={columns}
                data={data}
                //defaultSortField="templateID"
                pagination
                dense
                selectableRowsHighlight='true'
                compact
                highlightOnHover='true'
            />
            {
                showLoader ?
                    <section {...containerProps
                    } style={{ "margin-top": "0px", display: 'inline' }}>
                        {indicatorEl}
                    </section > : ""
            }
        </>
    )

}

export default SupplierMasterList;