import React, { useEffect, useState, useRef } from 'react';
import DataTable from "react-data-table-component";
import { waterConsumptionExcelUploadSubmit } from "../const";
import Swal from 'sweetalert2';
import { useLoading, Bars } from '@agney/react-loading';

const WaterConsumptionUploadsPreview = (tableData) => {
    const columns = [
        {
            name: "SERIAL NUMBER",
            selector: row => row.SERIAL_NUMBER,
            sortable: true,
        },
        {
            name: "OEM",
            selector: row => row.OEM,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "CATEGORY NAME",
            selector: row => row.CATEGORY_NAME,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "ACTIVITY TYPE",
            selector: row => row.ACTIVITY_TYPE,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "COMPONENT NAME",
            selector: row => row.COMPONENT_NAME,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "MATERIAL COMPONENT NAME",
            selector: row => row.MATERIAL_COMPONENT_NAME,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "ACTUAL WATER CONSUMPTION M3 PER UNIT",
            selector: row => row.ACTUAL_WATER_CONSUMPTION_M3_PER_UNIT,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "QUANTITY",
            selector: row => row.QUANTITY,
            sortable: true,
        },
        {
            name: "UNIT",
            selector: row => row.UNIT,
            sortable: true,
        },
        {
            name: "ACTUAL WATER CONSUMPTION M3",
            selector: row => row.ACTUAL_WATER_CONSUMPTION_M3,
            sortable: true,
        },
        {
            name: "FROM DATE",
            selector: row => row.FROM_DATE,
            sortable: true,
        },
        {
            name: "TO DATE",
            selector: row => row.TO_DATE,
            sortable: true,
        },
        {
            name: "Valid",
            selector: row => row.PRECHECK_VALIDATIONS ? 'YES' : 'NO',
            sortable: true,
        },
        {
            name: "Error",
            selector: row => row.PRECHECK_VALIDATION_MESSAGE,
            sortable: true,
            minWidth: '500px'
        }
    ];
    const [data, setData] = useState([]);
    const [errorCount, setErrorCount] = useState();
    const [totalCount, setTotalCount] = useState();
    const customRowStyles = (row) => {
        // console.log("row.PRECHECK_VALIDATION_MESSAGE:", row.children[2][0].props.row.PRECHECK_VALIDATIONS); // Log errorExist value
        return {
            color: row.children[2][0].props.row.PRECHECK_VALIDATIONS === true ? 'inherit !important' : (row.children[2][0].props.row.PRECHECK_VALIDATIONS === false ? 'red !important' : ''),
        }
    };
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="22" color="white" />,
    })
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        if (tableData.tableData.length !== 0) {
            setData(tableData.tableData);
            // console.log("tableData", tableData.tableData);
            const countWithErrorExist = data.filter(item => {
                // console.log("Processing item:", item.errorExist);
                return item.PRECHECK_VALIDATIONS === false;
            }).length;
            const totalCount = data.length;
            setErrorCount(countWithErrorExist);
            setTotalCount(totalCount)
            // console.log("Count of objects with errorExist=false:", errorCount);
        }
    })
    const uploadSubmit = () => {
        setShowLoader(true);
        let obj = {
            data: data
        }
        // console.log('obj', obj)
        waterConsumptionExcelUploadSubmit(obj).then(res => {
            if (res.status === 200) {
                setShowLoader(false);
                if (res.data.status.code === 'SUCCESS') {
                    Swal.fire({
                        title: "success!",
                        // text: "You clicked the button!",
                        icon: "success"
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    });
                }
            }
        })
    }
    return (
        <>
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">Carbon Emission</h2>
                </div>
                <div className='col-sm-6' style={{ display: "flex", justifyContent: "end" }}>
                    {errorCount !== 0 &&
                        <div className='mr-2' style={{ background: "red", borderRadius: "3px", padding: '5px', color: "white", fontWeight: "bold", margin: "1px" }}>
                            <strong>Errors : </strong> {errorCount}/{totalCount}
                        </div>
                    }

                    <input type="submit" onClick={uploadSubmit} disabled={errorCount !== 0} class="btn btn-sm btn-primary" />
                    {
                        showLoader ? (
                            <section {...containerProps} style={{ marginTop: "0px", display: 'inline-block', verticalAlign: 'middle' }}>
                                {indicatorEl}
                            </section>
                        ) : (
                            ""
                        )
                    }
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        //selectableRows
                        // selectableRowsComponent={BootyCheckbox}
                        // onSelectedRowsChange = {handleChange}
                        dense
                        selectableRowsHighlight='true'
                        compact
                        highlightOnHover='true'
                        striped
                        customStyles={{
                            rows: {
                                style: customRowStyles
                            }
                        }}
                    />
                </div>
            </div>


        </>
    )
}
export default WaterConsumptionUploadsPreview;