const Documents = () => {
    return (
        <>
            <div className="row">
                <div className="col-md-12 mb-0" style={{}}>
                    <div className="row">
                        <div className="col-md-3" style={{ textAlign: 'left', marginTop: 5 }}><strong>Upload Documents</strong></div>
                    </div>
                    <div className="row mb-0 mt-3">
                        <div className='col-md-3'>
                            <div className="form-group">
                                <select class="form-control form-control-sm" name="farmer">
                                    <option value="">-Select Document Name-</option>
                                    <option value="">Document 1</option>
                                    <option value="">Document 2</option>
                                    <option value="">Document 3</option>
                                    <option value="">Document 4</option>
                                    <option value="">Document 5</option>
                                </select>

                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="form-group">
                                <input type="file" accept=".xlsx,.xls,.xlxs" style={{ border: "1px solid black", borderRadius: "4px", padding: "3px" }} />

                            </div>
                        </div>

                        <div className="col mb-0">
                            <button style={{ marginTop: 0 }} className="btn btn-sm btn-primary">Submit</button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Documents