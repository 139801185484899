import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";

const LandingScreen = () => {
    const history = useHistory();
    const login = () => {
        history.push('/login');
    }
    const becomeMember = () => {
        history.push("/industries")
    }

    return (
        <>
            <div class="wrapper">
                <div>
                    <nav className="p-0 main-header navbar-fixed-top navbar navbar-expand-md navbar-light navbar-white">
                        <div class="container-fluid" style={{ paddingLeft: 20, paddingRight: 20 }}>
                            <NavLink exact to="/" class="navbar-brand">
                                <img src="/dist/img/logo.png" height="30" alt="RealGreen Logo"></img>&nbsp;
                            </NavLink>
                        </div>
                        <div class="dropdown-menu-right">
                            <span href="#" class="mr-4" style={{ display: 'flex', marginTop: "3%", cursor: "pointer" }} onClick={login}>
                                <h1 style={{ fontSize: "16px" }}>LOGIN</h1>
                            </span>
                        </div>
                    </nav>
                </div>
                <div>
                    <div className="position-relative mt-4">
                        <img src="/dist/img/coverimg.png" className="img-fluid" alt="logo" style={{ maxWidth: "100%", height: "auto" }} />
                        <div className="text-white p-5" style={{ marginTop: "-37%" }}>
                            <h1 className="display-2 font-weight-bold" style={{ fontSize: "50px" }}>
                                Carbon Emissions Matters,<br />
                                Made Simple.
                            </h1>
                            <button className="btn btn-success mt-5" style={{ width: "500px", height: "80px", fontSize: "25px", borderRadius: "50px" }}
                                onClick={becomeMember}>
                                Become a RealGreen Member
                            </button>
                        </div>
                    </div>



                </div>
            </div>
            {/* </div> */}
        </>
    )
}

export default LandingScreen;