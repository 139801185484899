
import React, { useEffect, useState, useRef } from 'react';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import Forecast from "./forecast";
import Composition from "./composition";
import Reports from "./reports"
import Overview from "./overview";
import { getDatesFromSetGoal } from "../const";
import moment from 'moment';

const Home = () => {
    const [manufacturedUnit, setManufacturedUnit] = useState('')
    const [submittedManufacturedUnit, setSubmittedManufacturedUnit] = useState('');
    const [dates, setDates] = useState('');

    const handleInputChange = (event) => {
        setManufacturedUnit(event.target.value)
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmittedManufacturedUnit(manufacturedUnit);
    };

    useEffect(() => {
        getDatesFromSetGoal().then(resp => {
            if (resp.status === 200 && resp.data.status.code === 'SUCCESS') {
                setDates(resp.data.primary[0])
            }
        })
    }, [])

    return (
        <div className="inwards">
            <div class="row mb-2">
                <div class="col">
                    <h2 class="m-0 text-dark">Scope 3<span style={{ textTransform: "none" }}> CO₂ e</span> Dashboard</h2>
                </div>

                <div class="col">
                    <h2 class="m-0 text-dark">From Date : {moment(dates.fromDate).format('DD-MMM-YYYY')}</h2>
                </div>
                <div class="col">
                    <h2 class="m-0 text-dark">To Date : {moment(dates.toDate).format('DD-MMM-YYYY')}</h2>
                </div>



                <div class="col">
                    <h2 class="m-0 text-dark">
                        <div className="inline-label-input" style={{ whiteSpace: "nowrap" }}>
                            <label htmlFor="manufacturedUnits" className="inline-label">Manufactured Units:</label>
                            <input type="text" id="manufacturedUnits" name="manufacturedUnits" className="inline-input"
                                onChange={handleInputChange}
                            />
                        </div>
                    </h2>
                </div>
                <div className='col-1'>
                    <button onClick={handleSubmit} className="btn btn-sm btn-primary">Submit</button>
                </div>
            </div>
            <div className="card">
                <div class="card-body">
                    <Tabs activeHeaderStyle={{ background: 'transparent' }}>
                        <Tab label="Overview">
                            <Overview enteredManufacturedUnit={submittedManufacturedUnit}></Overview>
                        </Tab>
                        <Tab label="Goal vs Actual">
                            <Forecast enteredManufacturedUnit={submittedManufacturedUnit}></Forecast>
                        </Tab>
                        <Tab label="Composition">
                            <Composition enteredManufacturedUnit={submittedManufacturedUnit}></Composition>
                        </Tab>
                        <Tab label="Reports">
                            <Reports enteredManufacturedUnit={submittedManufacturedUnit}></Reports>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}

export default Home;
