import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import MaterialIcon from 'material-icons-react';
const TopHeader = (props) => {

  const [partyType, setPartyType] = useState(sessionStorage.getItem('User'));
  // const [userName, setUserName] = useState(sessionStorage.getItem('partyType'));
  const [supplierCondition, setSupplierCondition] = useState(false);
  const [superAdminCondition, setSuperAdminCondition] = useState(false);
  const [adminCondition, setAdminCondition] = useState(false);

  // const navigate = useNavigate();
  const logout = () => {
    sessionStorage.clear()
    window.location = '/login';
    sessionStorage.clear()
  }

  useEffect(() => {
    console.log("SUPER ADMIN", partyType)
    if (partyType === 'supplier') {
      setSupplierCondition(true);
    }
    if (partyType === 'SUPER ADMIN') {
      setSuperAdminCondition(true)
    }
    if (partyType === 'Admin') {
      setAdminCondition(true)
    }
  })

  return (
    <div>
      <nav className="p-0 main-header navbar-fixed-top navbar navbar-expand-md navbar-light navbar-white">
        <div class="container-fluid" style={{ paddingLeft: 20, paddingRight: 20 }}>
          <NavLink exact to="#" class="navbar-brand">
            <img src="/dist/img/logo.png" height="30" alt="RealGreen Logo"></img>&nbsp;
          </NavLink>
          <button
            class="navbar-toggler order-1"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse order-3" id="navbarCollapse">
            <ul class="navbar-nav navBar">
              {adminCondition === true && (
                <>
                  <li class="nav-item">
                    <NavLink exact to="/home" className="nav-link">
                      Home
                    </NavLink>
                  </li>
                  <li class="nav-item">
                    <NavLink exact to="/breaKUpList" className="nav-link">
                      Set Goal
                    </NavLink>
                  </li>
                  <li class="nav-item dropdown">
                    <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle">Masters</a>
                    <ul aria-labelledby="dropdownSubMenu1" class="dropdown-menu border-0 shadow">
                      <NavLink exact to="/supplierMaster" className="nav-link">
                        Supplier Master
                      </NavLink>
                      <NavLink exact to="/materialMaster" className="nav-link">
                        Material Master
                      </NavLink>
                      <NavLink exact to="/OEMBOQ" className="nav-link">
                        BOQ
                      </NavLink>
                    </ul>
                  </li>

                </>
              )}
              {
                superAdminCondition === true && (
                  <li class="nav-item">
                    <NavLink exact to="/ActivityIdentification" className="nav-link">
                      Activity ID
                    </NavLink>
                  </li>
                )
              }
              {supplierCondition === true && (
                <>
                  <li class="nav-item">
                    <NavLink exact to="/actualCO2eReport" className="nav-link">
                      Actual<span style={{ textTransform: "none" }}> CO₂ e</span> Report
                    </NavLink>
                  </li>
                  <li class="nav-item">
                    <NavLink exact to="/goalActualReport" className="nav-link">
                      Goal vs Actual
                    </NavLink>
                  </li>
                  <li class="nav-item">
                    <NavLink exact to="/componentBOQReport" className="nav-link">
                      Component BOQ
                    </NavLink>
                  </li>
                  <li class="nav-item">
                    <NavLink exact to="/uploads" className="nav-link">
                      Uploads
                    </NavLink>
                  </li>
                  {/* <li class="nav-item dropdown">
                    <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle">Uploads</a>
                    <ul aria-labelledby="dropdownSubMenu1" class="dropdown-menu border-0 shadow">
                      <NavLink exact to="/" className="nav-link">
                        BOQ
                      </NavLink>
                      <NavLink exact to="/" className="nav-link">
                        Carbon Emission
                      </NavLink>
                    </ul>
                  </li> */}
                </>
              )
              }
            </ul>

          </div>

          <ul class="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">

            <li class="nav-item dropdown">
              <a class="nav-link alertIcon" data-toggle="dropdown" href="#">
                {/* <i class="far fa-bell"></i> */}
                <MaterialIcon icon='notifications_none' size={24} />
                <span class="badge badge-warning navbar-badge">15</span>
              </a>
              <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <span class="dropdown-header">15 Notifications</span>
                <div class="dropdown-divider"></div>
                <a href="#" class="dropdown-item">
                  <i class="fas fa-envelope mr-2"></i> 4 new messages
                  <span class="float-right text-muted text-sm">3 mins</span>
                </a>
                <div class="dropdown-divider"></div>
                <a href="#" class="dropdown-item dropdown-footer">
                  See All Notifications
                </a>
              </div>
            </li>
            <li className="loggedas">Logged as: </li>
            <li class="nav-item dropdown">
              <a class="nav-link loggedasButton" style={{ textTransform: "capitalize" }} data-toggle="dropdown" href="#">
                {sessionStorage.getItem('User')} <i class="fa fa-caret-down" aria-hidden="true"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-md dropdown-menu-right">
                {/* <a href="#" class="dropdown-item">
                  <i class="fas fa-envelope mr-2"></i> View Profile
                  
                </a> */}
                <span href="#" class="dropdown-item" onClick={logout}>
                  <i class="fa fa-sign-out" aria-hidden="true"></i> Logout
                </span>
              </div>
            </li>
          </ul>
        </div>
      </nav >
    </div >
  );
};
export default TopHeader;
