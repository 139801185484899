import React from 'react';
import ReactEcharts from 'echarts-for-react';

const DownstreamChart = ({ ListOne, ListTwo }) => {
    const getRandomColor = () => {
        return '#' + Math.floor(Math.random() * 16777215).toString(16); // Generate a hexadecimal color code
    };

    const options = {
        angleAxis: {},
        radiusAxis: {
            axisLabel: {
                show: false, // Ensure axis labels are visible
                interval: 0, // Display all labels
                rotate: 0, // Rotate labels for better readability if necessary
            },
            type: 'category',
            data: ListOne,
            z: 10
        },
        polar: {},
        series: [
            {
                type: 'bar',
                data: ListTwo,
                coordinateSystem: 'polar',
                emphasis: {
                    focus: 'series'
                },
            }
        ],
        tooltip: {
            show: true,
        },
        legend: {
            show: false,
            position: 'middle',
        }
    };

    return (
        <div style={{ height: '250px' }}>
            <ReactEcharts
                option={options}
                style={{ height: '100%', width: '100%' }}
                opts={{ renderer: 'svg' }}
            />
        </div>
    );
};

export default DownstreamChart;
