import React from 'react';
import ReactEcharts from 'echarts-for-react';

const SupplierWiseBarchart = ({ topSuppliersData }) => {
    let options = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            data: ["Actual", "Goal"],
            orient: 'horizontal', // This is optional as 'horizontal' is the default value
            top: '0%',

        },
        grid: {

            left: 40,
            top: 25,
            right: 35,
            bottom: 60,
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: topSuppliersData.xData,
                axisTick: {
                    alignWithLabel: true
                },
                name: 'Suppliers',
                nameLocation: 'middle',
                nameGap: 30
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: 'kg CO₂ e',
                nameLocation: 'middle',
                nameGap: 50
            }
        ],
        series: [

            {
                name: "Actual",
                type: 'bar',
                barWidth: '15%',
                //stack: 'total',
                label: {
                    show: false
                },
                emphasis: {
                    focus: 'series'
                },
                data: topSuppliersData.actualData,
            },
            {
                name: 'Goal',
                type: 'bar',
                barWidth: '15%',
                //stack: 'total',
                label: {
                    show: false
                },
                emphasis: {
                    focus: 'series'
                },
                data: topSuppliersData.goalData,

            },

        ],
    };

    return (
        <div className='mt-3' style={{ height: '300px' }}>
            <ReactEcharts
                option={options}
                style={{ height: '100%', width: '100%' }}
                opts={{ renderer: 'svg' }}
            />
        </div>
    );
}

export default SupplierWiseBarchart;