import React, { useEffect, useState } from 'react';
import RadialBarChart from "./upstreamChart"
import DownstreamChart from "./downstreamChart"
import ComponentWiseLineChart from "./componentWiseLineChart"
import SupplierWiseBarchart from "./supplierWiseBarchart"
import MOMLineChart from "./MOMEmissionChart"
import { upStreamAndDownStreamAvg, upStreamAndDownStreamActivities, top3SuppliersUnderScope3Emission, top5ComponentsUnderScope3Emission, monthOnMonthEmissions } from "../const"
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';

const Overview = ({ enteredManufacturedUnit }) => {
    const [counts, setCounts] = useState();
    const [upstreamListOne, setUpstreamListOne] = useState([]);
    const [upstreamListTwo, setUpstreamListTwo] = useState([]);

    const [downstreamListOne, setDownstreamListOne] = useState([]);
    const [downstreamListTwo, setDownstreamListTwo] = useState([]);

    const [topSuppliers, setTopSuppliers] = useState({});

    const [topComponents, setTopComponents] = useState({});

    const [momEmissions, setMOMEmissions] = useState({});

    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="22" color="#333" />,
    })
    const [showLoader, setShowLoader] = useState(false);


    useEffect(() => {
        setShowLoader(true);
        upStreamAndDownStreamAvg(enteredManufacturedUnit).then(resp => {
            if (resp.status === 200 && resp.data.status.code === 'SUCCESS') {
                setCounts(resp.data.primary)
            }
        });
        upStreamAndDownStreamActivities(enteredManufacturedUnit).then(res => {
            // console.log("res", res)
            const colorsArray = ['#B0C5A4', '#D37676', '#124076a8', '#7F9F80', '#F9E897', '#91cc75']
            if (res.status === 200 && res.data.status.code === 'SUCCESS') {
                if (res.data.primary.upstreamActivity !== 0) {
                    const data = res.data.primary.upstreamActivity.map(item => item.categoryName);
                    const data1 = res.data.primary.upstreamActivity.map((item, index) => ({
                        value: item.actualCo2eEmission.toFixed(2),
                        itemStyle: {
                            color: colorsArray[index % colorsArray.length] // Use modulo to cycle through colors if needed
                        }
                    }));
                    setUpstreamListOne(data)
                    setUpstreamListTwo(data1)
                }
                if (res.data.primary.downStreamActivity !== 0) {
                    const dataDownStream = res.data.primary.downStreamActivity.map(item => item.categoryName);
                    const data1DownStream = res.data.primary.downStreamActivity.map((item, index) => ({
                        value: item.actualCo2eEmission.toFixed(2),
                        itemStyle: {
                            color: colorsArray[index % colorsArray.length] // Use modulo to cycle through colors if needed
                        }
                    }));
                    setDownstreamListOne(dataDownStream)
                    setDownstreamListTwo(data1DownStream)
                }
            }
        })
        top3SuppliersUnderScope3Emission(enteredManufacturedUnit).then(resData => {
            if (resData.status === 200 && resData.data.status.code === 'SUCCESS') {
                let data = resData.data.primary
                // console.log("top3SuppliersUnderScope3Emission", resData)
                let xData = [];
                let actualData = [];
                let goalData = [];
                data.forEach(item => {
                    xData.push(item.createdBy);
                    actualData.push(item.actual.toFixed(2));
                    goalData.push(item.goal);
                });
                let result = {
                    xData: xData,
                    actualData: actualData,
                    goalData: goalData
                };

                // console.log(result);
                setTopSuppliers(result)
            }
        });
        top5ComponentsUnderScope3Emission(enteredManufacturedUnit).then(respData => {
            if (respData.status === 200 && respData.data.status.code === 'SUCCESS') {
                let data = respData.data.primary;
                let xData = [];
                let actualData = [];
                let goalData = [];
                let baseData = [];
                data.forEach(item => {
                    xData.push(item.componentName);
                    actualData.push(item.actual.toFixed(2));
                    goalData.push(item.goal);
                    baseData.push(item.base)
                });
                let result = {
                    xData: xData,
                    actualData: actualData,
                    goalData: goalData,
                    baseData: baseData
                };

                // console.log(result);
                setTopComponents(result)
            }
        });
        monthOnMonthEmissions(enteredManufacturedUnit).then(MOMData => {
            if (MOMData.status === 200 && MOMData.data.status.code === 'SUCCESS') {
                let data = MOMData.data.primary;
                // console.log("MOMData", data)
                let xData = [];
                let currentYear = [];
                let previousYear = [];
                data.forEach(item => {
                    xData.push(item.formattedMonth);
                    currentYear.push(item.currentYear.toFixed(2));
                    previousYear.push(item.previousYear.toFixed(2));
                });
                let result = {
                    xData: xData,
                    currentYear: currentYear,
                    previousYear: previousYear,
                };
                setMOMEmissions(result);

                setShowLoader(false);
            }
        })
    }, [enteredManufacturedUnit])


    const formatNumber = (number) => {
        return parseFloat(number).toLocaleString('en', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };

    return (
        <>
            {
                showLoader ?
                    <section {...containerProps
                    } style={{ "margin-top": "10%", display: 'inline' }}>
                        {indicatorEl}
                    </section > : (
                        <>
                            <div className="row">
                                <div className="col-md-12">
                                    {counts &&
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="borderRight">
                                                            <div className="row">
                                                                <h3 className='text-dark'>Carbon Emission (KG) -<span style={{ fontWeight: "bold" }}>  {counts.carbonEmissionOfActual}</span></h3>
                                                            </div>
                                                            <div className='row mt-3'>
                                                                <h4>UPSTREAM Activities (<span style={{ fontWeight: "bolder" }}> {formatNumber(counts.upStreamPercentage['upstreamPercentage'])} %</span>
                                                                    {
                                                                        counts.upStreamPercentage.status === "decrease" &&
                                                                        (<img className="mb-1 ml-2" style={{ height: "18px" }} src="/dist/img/decrement.png" />)
                                                                    }
                                                                    {
                                                                        counts.upStreamPercentage.status === "increate" &&
                                                                        (<img className="mb-1 ml-2" style={{ height: "18px" }} src="/dist/img/increment.png" />)
                                                                    }
                                                                    )</h4>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-md-6' style={{ whiteSpace: "nowrap" }}>
                                                                    <span style={{ fontSize: "medium" }}>Actual</span>
                                                                    {
                                                                        counts.upstreamACtualValue['actualValueOfUpstream'].toFixed(2) > counts.upstreamACtualValue['actualValueOfGoalUpstream'] ?
                                                                            <h1 style={{ fontWeight: "bolder", color: "red" }}>{formatNumber(counts.upstreamACtualValue['actualValueOfUpstream'].toFixed(2))}</h1> :
                                                                            <h1 style={{ fontWeight: "bolder", color: "green" }}>{formatNumber(counts.upstreamACtualValue['actualValueOfUpstream'].toFixed(2))}</h1>
                                                                    }
                                                                    {/* <br></br> */}
                                                                </div>
                                                                <div className='col-md-6 borderLeft' style={{ whiteSpace: "nowrap" }}>
                                                                    <span style={{ fontSize: "medium" }}>Goal</span>
                                                                    <h1 style={{ fontWeight: "bolder" }}>{formatNumber(counts.upstreamACtualValue['actualValueOfGoalUpstream'])}</h1>
                                                                    {/* <br></br> */}
                                                                </div>
                                                            </div>
                                                            <div className='row mt-3'>
                                                                <h4>DOWNSTREAM Activities (<span style={{ fontWeight: "bolder" }}> {formatNumber(counts.downStreamPercentage['downstreamPercentage'])}  %</span>
                                                                    {
                                                                        counts.downStreamPercentage.status === "decrease" &&
                                                                        (<img className="mb-1 ml-2" style={{ height: "18px" }} src="/dist/img/decrement.png" />)
                                                                    }
                                                                    {
                                                                        counts.downStreamPercentage.status === "increate" &&
                                                                        (<img className="mb-1 ml-2" style={{ height: "18px" }} src="/dist/img/increment.png" />)
                                                                    }
                                                                    )</h4>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-md-6' style={{ whiteSpace: "nowrap" }}>
                                                                    <span style={{ fontSize: "medium" }}>Actual</span>
                                                                    {
                                                                        counts.downStreamActualValue['actualValueOfDownstream'].toFixed(2) > counts.downStreamActualValue['actualValueOfGoalDownstream'] ?
                                                                            <h1 style={{ fontWeight: "bolder", color: "red" }}>{formatNumber(counts.downStreamActualValue['actualValueOfDownstream'].toFixed(2))}</h1> :
                                                                            <h1 style={{ fontWeight: "bolder", color: "green" }}>{formatNumber(counts.downStreamActualValue['actualValueOfDownstream'].toFixed(2))}</h1>
                                                                    }
                                                                    {/* <br></br> */}
                                                                </div>
                                                                <div className='col-md-6 borderLeft' style={{ whiteSpace: "nowrap" }}>
                                                                    <span style={{ fontSize: "medium" }}>Goal</span>
                                                                    <h1 style={{ fontWeight: "bolder" }}>{formatNumber(counts.downStreamActualValue['actualValueOfGoalDownstream'])}</h1>
                                                                    {/* <br></br> */}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="borderRight">
                                                            <div className="row">
                                                                <h3 className='text-dark'>Upstream Activities (in Kg CO₂ e)</h3>
                                                            </div>

                                                            <div className="widgetText">
                                                                <RadialBarChart ListOne={upstreamListOne} ListTwo={upstreamListTwo}></RadialBarChart>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="">
                                                            <div className="row">
                                                                <h3 className='text-dark'>Downstream Activities (in Kg CO₂ e)</h3>
                                                            </div>

                                                            <div className="widgetText">
                                                                <DownstreamChart ListOne={downstreamListOne} ListTwo={downstreamListTwo}></DownstreamChart>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                            </div>
                            <div className='row'>
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className='col-md-6'>
                                                    <div className="borderRight">
                                                        <div className="row">
                                                            <h3 className='text-dark'>Top Suppliers Contributing Scope 3 Emission </h3>
                                                        </div>
                                                        <div className="widgetText">
                                                            <SupplierWiseBarchart topSuppliersData={topSuppliers}></SupplierWiseBarchart>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="">
                                                        <div className="row">
                                                            <h3 className='text-dark'>Top Components Contributing Scope 3 Emission </h3>
                                                        </div>
                                                        <div className="widgetText">
                                                            <ComponentWiseLineChart topComponentsData={topComponents}></ComponentWiseLineChart>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className='col-md-12'>
                                                    <div className="">
                                                        <div className="row">
                                                            <h3 className='text-dark'>Month On Month Emissions </h3>
                                                        </div>
                                                        <div className="widgetText">
                                                            <MOMLineChart momEmissionsData={momEmissions}></MOMLineChart>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
            }
            {/* <h2 className='text-dark'>Welcome : </h2> */}



        </>
    )
}

export default Overview;