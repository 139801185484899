import { useForm, Controller } from "react-hook-form";
import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { getProductComponentsUnderIndustryType, getActualAndBaseValueSumInEveryProduct, updateActualValue } from "../const"
import { NavLink } from "react-router-dom";

const Calculator = (props) => {
    // console.log("components", components)
    let [data, setData] = useState()
    let [actualCalculateValue, setActualCalculateValue] = useState(0)
    let [outcomeValue, setOutcomeValue] = useState(0)
    const [components, setComponentsData] = useState([]);
    const [sumOfIdealValue, setSumIdealValue] = useState(0);
    const [summaryDetails, setSummary] = useState();
    const [actualValues, setActualValues] = useState({});
    const history = useHistory();
    const [signupSet, setsignUp] = useState(false)
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: 'onChange'
    });

    useEffect(() => {
        getProductComponentsUnderIndustryType({ productId: props.location.state.productId, industryId: props.location.state.industryId, countryId: props.location.state.countryId }).then(res => {
            if (res.status === 200 && res.data.status.code === 'SUCCESS') {
                setComponentsData(res.data.primary);
                setData(res.data.primary);
                // console.log("data", data)
                // const sumIdealValue = res.data.primary.reduce((sum, item) => sum + item.idealValue, 0);
                // setSumIdealValue(sumIdealValue)
            }
        })
    }, [])

    const onSubmit = (formData, e) => {
        setsignUp(true)
        const updatedComponents = data.map((item, index) => ({
            // ...item,
            // prevValue: components[index].idealValue, // Previous value
            // newValue: item.idealValue, // New value
            nodeId: item.componentId,
            domainId: item.productId,
            industryId: item.industryId,
            idealValue: components[index].idealValue,
            actualvalue: parseFloat(actualValues[item.componentName]) || parseFloat(item.idealValue),
            refUnit: item.refUnit,
            countryId: props.location.state.countryId
        }));
        // Now 'updatedComponents' contains the updated components with previous and new values
        console.log("Updated Components:", updatedComponents);
        sessionStorage.setItem("calculateData", JSON.stringify(updatedComponents));
        updateActualValue({ electronics: JSON.parse(sessionStorage.getItem("calculateData")) }).then(respData => {
            if (respData.status === 200 && respData.data.status.code === 'SUCCESS') {
                getActualAndBaseValueSumInEveryProduct({ productId: props.location.state.productId, industryId: props.location.state.industryId, countryId: props.location.state.countryId }).then(res => {
                    if (res.status === 200 && res.data.status.code === 'SUCCESS') {
                        setSummary(res.data.primary.status)
                        setActualCalculateValue(res.data.primary.actualValue);
                        setSumIdealValue(res.data.primary.baseValueInfo)
                        setOutcomeValue(Math.abs(res.data.primary.outcome))
                    }
                })
            }
        })

        // const sumValue = updatedComponents.reduce((sum, item) => sum + item.actualvalue, 0);
        // const verdict = (1 - (sumValue / sumOfIdealValue)) * 100;
        // console.log("verdict", verdict)
        // const formattedValue = verdict.toFixed(2);
        // setOutcomeValue(formattedValue)
    }


    const [showResults, setShowResults] = useState(false)
    const [resultMessage, setResultMessage] = useState({});

    const signup = () => {
        history.push('/login');
    }

    const formatNumber = (number) => {
        return parseFloat(number).toLocaleString('en', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };


    const handleChange = (e, index) => {
        // const { value } = e.target;
        const { name, value } = e.target;
        setActualValues(prevValues => ({
            ...prevValues,
            [name]: value,
        }));
        // console.log("1111", actualValues)
        // setData(prevData => {
        //     const newData = prevData.map((item, i) => {
        //         if (i === index) {
        //             // Store the current idealValue as prevValue
        //             return {
        //                 ...item,
        //                 prevValue: item.idealValue,
        //                 idealValue: actualValues[0]
        //             };
        //         }
        //         return item;
        //     });
        //     console.log("222", newData)
        //     return newData;
        // });
        // console.log("data", data)

    };

    const reset = () => {
        setsignUp(false)
        setActualValues({})
    }
    return (
        <>
            <div class="wrapper">
                <div>
                    <nav className="p-0 main-header navbar-fixed-top navbar navbar-expand-md navbar-light navbar-white">
                        <div class="container-fluid" style={{ paddingLeft: 20, paddingRight: 20 }}>
                            <NavLink exact to="/" class="navbar-brand">
                                <img src="/dist/img/logo.png" height="30" alt="RealGreen Logo"></img>&nbsp;
                            </NavLink>
                        </div>
                    </nav>
                </div>
                <div className="myappcontent">
                    <div className="container-fluid">
                        <div className="dashboard">
                            {
                                data &&
                                <>
                                    <div className='workHeader'>

                                        <div className='row'>

                                            <div className='col-md-4'>
                                                <h2><strong>Industry:</strong> {components[0].industryName}</h2>
                                            </div>
                                            <div className='col-md-4'>
                                                <strong>Finished Good: </strong>   {components[0].productName}
                                            </div>
                                            <div className='col-md-3'>
                                                <div className='status'>
                                                    <strong>Manufactured Units: </strong>  {components[0].manufacturingUnit}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-3 mt-3 row">
                                        <div className="col-md-12">
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <div className='row'>
                                                    <div className='col-md-9'>
                                                        {showResults ? <Results key={Math.random()} message={resultMessage.message} error={resultMessage.error} /> : null}
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    {data.map((item, index) => (
                                                        <div className='col' key={item.componentName}>
                                                            <div className="form-group">
                                                                <label htmlFor={item.componentName}>{item.componentName} (Kg  CO₂ e)</label>
                                                                <div className="row">
                                                                    <div className="col-4">
                                                                        <label htmlFor={`base-${index}`} style={{ color: "black", fontWeight: "400" }}>Base Value</label>
                                                                        <input
                                                                            type="text"
                                                                            id={`base-${index}`}
                                                                            className="form-control form-control-sm"
                                                                            name={item.componentName}
                                                                            value={item.idealValue}
                                                                            data-index={index}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <label htmlFor={`actual-${item.componentName}`} style={{ color: "black", fontWeight: "400" }}>Actual Value</label>
                                                                        <input
                                                                            type="text"
                                                                            id={`actual-${item.componentName}`}
                                                                            className="form-control form-control-sm"
                                                                            placeholder="Input Actual CO₂ e"
                                                                            name={item.componentName}
                                                                            value={actualValues[item.componentName] || ''}
                                                                            data-index={index}
                                                                            onChange={(e) => handleChange(e, index)}
                                                                        />
                                                                        {errors[item.componentName] && <span className="err-msg">{errors[item.componentName].message}</span>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>



                                                <div className='row' >
                                                    <div className='col-md-12' style={{ display: "flex", justifyContent: "end", marginBottom: "2%" }}>
                                                        <input type="submit" value="Submit" class="btn btn-primary newBtn" />
                                                        <input type="reset" value="Reset" class="btn btn-secondary newBtn" onClick={reset} />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </>
                            }
                            {signupSet &&

                                <div className="row" >
                                    <div className="col-md-6">
                                        <h1 class="m-0" style={{ fontSize: "25px", fontWeight: "600", display: 'inline', textTransform: "none", color: "green" }}>
                                            Scope 3 emission Summary
                                        </h1>
                                        <table class="mt-4 table table-condensed table-striped" >
                                            <tbody>
                                                <tr>
                                                    <td> &nbsp;Actual kg CO<sub>2</sub> e</td>
                                                    <td className="number">{formatNumber(actualCalculateValue)}</td>
                                                </tr>
                                                <tr>
                                                    <td> &nbsp;Base Line kg CO<sub>2</sub> e</td>
                                                    <td className="number">{formatNumber(sumOfIdealValue)}</td>
                                                </tr>
                                                <tr>
                                                    <td> &nbsp;Outcome</td>
                                                    <td className="number">{formatNumber(outcomeValue)} %
                                                        {
                                                            summaryDetails === "increate" ?
                                                                (<img className="mb-1 ml-2" src="/dist/img/increment.png" />)
                                                                :
                                                                <img className="mb-1 ml-2" src="/dist/img/decrement.png" />
                                                        }

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-6">
                                        <h1 class="m-0" style={{ fontSize: "25px", fontWeight: "600", display: 'inline', textTransform: "none", color: "green" }}>
                                            Want to Explore below features
                                        </h1>

                                        <ul style={{ marginTop: "2%", fontSize: "18px" }}>
                                            <li style={{ padding: "1%" }}>Goal Setting</li>
                                            <li style={{ padding: "1%" }}>Targets v/s Actual Emissions</li>
                                            <li style={{ padding: "1%" }}>Breakdown of Emission data by Activity</li>
                                            <li style={{ padding: "1%" }}>LCA Activites</li>
                                            <li style={{ padding: "1%" }}>Dashboard & Reports</li>
                                        </ul>
                                        <div style={{ textAlign: 'right' }}>
                                            {/* <button className="btn-primary mr-3" onClick={signup}>SignUP</button> */}
                                            <input onClick={signup} type="submit" value="Sign Up" style={{ background: "#BFE0CA" }} class="btn newBtn" />
                                        </div>
                                    </div>

                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

const Results = (props) => {
    let error, classes;
    if (props.error === false) {
        error = 'Success';
        classes = 'alert alert-success alert-dismissible fade show'
    } else {
        error = 'Error!';
        classes = 'alert alert-danger alert-dismissible fade show'
    }
    return (
        <div className="results">
            <div className={classes}>
                <strong>{error}</strong> {props.message}
                <button type="button" class="close" data-dismiss="alert">&times;</button>
            </div>
        </div>
    );
}

export default Calculator;
