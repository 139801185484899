import DataTable from "react-data-table-component";
import React, { useState, useEffect } from 'react';
import { activityList, getActivityListDetails } from "./const";
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';
import ActivityInfo from "./ActivityInfo"
const ActivityIdentificationList = () => {
    const columns = [
        {
            name: "Industry Type",
            selector: row => row.industryType,
            sortable: true,
        },
        {
            name: "Component Level",
            selector: row => row.componentLevel,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Data Base",
            selector: row => row.dataBase,
            sortable: true,
        },
        {
            name: "Finished Good",
            selector: row => row.productName,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Component Name",
            selector: row => row.componentName,
            sortable: true,
        },
        {
            name: "Activity Id",
            selector: row => row.activityId,
            sortable: true,
        },
        {
            name: "Manufacturing Location",
            selector: row => row.location,
            sortable: true,
        },
        {
            name: "Components Linked",
            selector: row => row.VERSION_NUMBER,
            cell: (row, index) => (
                <>
                    {row.finishedGood === true ?
                        (

                            < span
                                style={{ fontSize: 14, marginLeft: 10, color: "blue", cursor: "pointer" }}
                                onClick={() => popup(row)}  // Make sure to use an arrow function here
                            // id={row.materialId}
                            >
                                View
                            </span>
                        ) : (
                            < span > NA</span >
                        )
                    }

                </>
            )

        }
    ];
    const [data, setData] = useState([]);
    const [selectedData, setSelectedData] = useState([])
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="22" color="#333" />,
    });
    const [showLoader, setShowLoader] = useState(false);
    const [showMaterialInfoPopup, setShowMaterialInfoPopup] = useState(false);
    const [productName, setProductName] = useState('')

    const popup = (data) => {
        console.log("ccccc", data);
        setProductName(data.productName)
        let obj = {
            productName: data.productName
        }
        getActivityListDetails(obj).then(res => {
            if (res.status === 200 && res.data.status === 'success') {
                setSelectedData(res.data.data);
                setShowMaterialInfoPopup(true)
            }
        })
    }

    useEffect(() => {
        setShowLoader(true);
        activityList().then(res => {
            if (res.status === 200 && res.data.status === 'success') {
                setData(res.data.data);
                setShowLoader(false);
            }
        })
    }, [])

    return (
        <>
            <DataTable
                columns={columns}
                data={data}
                pagination
                dense
                selectableRowsHighlight='true'
                compact
                highlightOnHover='true'
            />
            <ActivityInfo key={Math.random()}
                showPopup={showMaterialInfoPopup}
                popupData={selectedData}
                productName={productName}
                closePopup={() => { setShowMaterialInfoPopup(false) }}
            ></ActivityInfo>
            {
                showLoader ?
                    <section {...containerProps
                    } style={{ "margin-top": "0px", display: 'inline' }}>
                        {indicatorEl}
                    </section > : ""
            }
        </>
    )

}

export default ActivityIdentificationList;