import React, { useState, useRef, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { SupplierCreationInOEM } from "../const";
import Swal from 'sweetalert2';


const SupplierMaster = () => {
    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: 'onChange'
    });

    const [showResults, setShowResults] = useState(false)
    const [resultMessage, setResultMessage] = useState({});


    const onSubmit = (data, e) => {
        // console.log("data", data)
        SupplierCreationInOEM(data).then(res => {
            if (res.status === 200 && res.data.status.code === 'SUCCESS') {
                // console.log("11111", res)
                Swal.fire({
                    title: "Success",
                    text: res.data.primary,
                    icon: "success"
                }).then((result) => {
                    if (result.isConfirmed) {
                        e.target.reset();
                    }
                })
            }
        })
    }




    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='row'>
                    <div className='col-md-9'>
                        {showResults ? <Results key={Math.random()} message={resultMessage.message} error={resultMessage.error} /> : null}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-4'>
                        <div className="form-group">
                            <label htmlFor>Supplier ID</label>
                            <input type="text" className="form-control form-control-sm" placeholder="Enter Supplier ID"
                                name="supplierId"
                                {...register("supplierId", {
                                    required: "Please enter Supplier ID",

                                })}
                            />
                            {errors.supplierId && <span className="err-msg">{errors.supplierId.message}</span>}
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className="form-group">
                            <label htmlFor>Supplier Name</label>
                            <input type="text" className="form-control form-control-sm" placeholder="Enter Supplier Name"
                                name="supplierName"
                                {...register("supplierName", {
                                    required: "Please enter Supplier Name",

                                })}
                            />
                            {errors.supplierName && <span className="err-msg">{errors.supplierName.message}</span>}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className="form-group">
                            <label htmlFor>Admin Mail ID</label>
                            <input type="email" className="form-control form-control-sm" placeholder="Enter Admin Mail ID"
                                name="adminMailId"
                                {...register("adminMailId", {
                                    required: "Please enter Admin Mail ID",
                                })}
                            />
                            {errors.adminMailId && <span className="err-msg">{errors.adminMailId.message}</span>}
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="form-group">
                            <label htmlFor>Admin Contact</label>
                            <input type="number" className="form-control form-control-sm" placeholder="Enter Admin Contact"
                                name="adminContact"
                                {...register("adminContact", {
                                    required: "Please enter Admin Contact",
                                })}
                            />
                            {errors.adminContact && <span className="err-msg">{errors.adminContact.message}</span>}
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="form-group">
                            <label htmlFor>Address</label>
                            <textarea className="form-control form-control-sm" placeholder="Enter Address"
                                name="address"
                                {...register("address", {
                                    required: "Please enter Address",

                                })}
                            />
                            {errors.address && <span className="err-msg">{errors.address.message}</span>}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <input type="submit" value="Submit" class="btn btn-primary newBtn" />
                        <input type="reset" value="Reset" class="btn btn-secondary newBtn" />
                    </div>
                </div>
            </form>
        </>
    );
}

const Results = (props) => {
    let error, classes;
    if (props.error === false) {
        error = 'Success';
        classes = 'alert alert-success alert-dismissible fade show'
    } else {
        error = 'Error!';
        classes = 'alert alert-danger alert-dismissible fade show'
    }
    return (
        <div className="results">
            <div className={classes}>
                <strong>{error}</strong> {props.message}
                <button type="button" class="close" data-dismiss="alert">&times;</button>
            </div>
        </div>
    );
}

export default SupplierMaster;