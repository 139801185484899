import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { sankeyDiagramForEveryComponent } from "../const";
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';

const SankeyChart = ({ id, mainComp, enteredManufacturedUnit, selectedDropdownValue }) => {
    const [data, setData] = useState({ data: [], links: [] });
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="22" color="#333" />,
    })
    const [showLoader, setShowLoader] = useState(false);
    useEffect(() => {
        setShowLoader(true);
        let componentName;
        if (id !== "") {
            if (id === mainComp) {
                componentName = "";
            } else {
                componentName = id;
            }
            let obj
            if (enteredManufacturedUnit) {
                obj = {
                    productName: mainComp,
                    componentName: componentName,
                    manufacturingUnit: enteredManufacturedUnit,
                    consumptionType: selectedDropdownValue
                }
            }
            else {
                obj = {
                    productName: mainComp,
                    componentName: componentName,
                    consumptionType: selectedDropdownValue
                }
            }
            sankeyDiagramForEveryComponent(obj)
                .then(resp => {
                    if (resp.status === 200 && resp.data.status.code === 'SUCCESS') {
                        setShowLoader(false)
                        setData(resp.data.primary);
                    }
                })
                .catch(error => {
                    setShowLoader(false)
                    console.error("Error fetching data:", error);
                });
        }
    }, [id, mainComp, selectedDropdownValue]);

    const options = {
        tooltip: {
            trigger: 'item',
            triggerOn: 'mousemove'
        },
        series: [
            {
                type: 'sankey',
                data: data.data,
                links: data.links,
                orient: 'vertical',
                label: 0,
                emphasis: {
                    focus: 'adjacency',
                    fontSize: 5
                },
                levels: [
                    { depth: 0, itemStyle: { color: '#fbb4ae' }, lineStyle: { color: 'source', opacity: 0.6 } },
                    { depth: 1, itemStyle: { color: '#b3cde3' }, lineStyle: { color: 'source', opacity: 0.6 } },
                    { depth: 2, itemStyle: { color: '#ccebc5' }, lineStyle: { color: 'source', opacity: 0.6 } },
                    { depth: 3, itemStyle: { color: '#decbe4' }, lineStyle: { color: 'source', opacity: 0.6 } },
                    { depth: 4, itemStyle: { color: '#becbe2' }, lineStyle: { color: 'source', opacity: 0.6 } },
                ],
                lineStyle: {
                    color: 'source',
                    curveness: 0.5
                }
            }
        ]
    };

    return (
        <>
            <div>
                <ReactEcharts
                    option={options}
                    style={{ height: '600px' }}
                    opts={{ renderer: 'svg' }}
                />
                {
                    showLoader ?
                        <section {...containerProps
                        } style={{ "margin-top": "10%", display: 'inline' }}>
                            {indicatorEl}
                        </section > : ""
                }
            </div>

        </>

    );
};

export default SankeyChart;
