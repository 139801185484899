import axios from 'axios';

const baseUrl = " https://api.generic.innovation.realware.tech/features/Sustainability/";
const keycloakUrl = 'https://auth.innovation.realware.app/realms/REALGREEN/protocol/openid-connect/token';

// const headerConfig = {
//     "Content_Type": "application/json",
//     Authorization: `Bearer ${sessionStorage.getItem('token')}`,
// }

export async function keycloak(data) {
    let formBody = [];
    for (let property in data) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(data[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const basicHeaders = {
        "Content-Type": "application/x-www-form-urlencoded",
    };
    const options = { headers: basicHeaders };
    return await axios.post(keycloakUrl, formBody, options);
}


export async function loggedUserPartyInfo(token) {
    const headerConfigs = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${token}`,
    }
    const options = { headers: headerConfigs };
    console.log("options", options)
    return await axios.post("https://api.generic.innovation.realware.tech/features/Sustainability/loggedUserPartyInfo", {}, options)
}


export async function linkedInredirect(obj) {
    const basicHeaders = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        // "Authorization": "Bearer " + token,
    };
    const options = { headers: basicHeaders };
    const url = "https://lca.realware.tech/realgreenauth/linkedIn"
    return await axios.post(url, obj, options)
}



export async function getIndustryTypeList(industry) {
    const header = {
        "Content-Type": "application/json",
    }
    const options = { headers: header, params: industry };
    const url = 'https://lca.realware.tech/realgreenchickenPasta/getIndustryTypeList'
    return await axios.get(url, options)
}


export async function getProductComponentsUnderIndustryType(productId) {
    const header = {
        "Content-Type": "application/json",
    }
    const options = { headers: header, params: productId };
    const url = 'https://lca.realware.tech/realgreenchickenPasta/getProductComponentsUnderIndustryType'
    return await axios.get(url, options)
}


export async function createActualValue(obj) {
    const headerConfigs = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfigs };
    console.log("options1111", options)
    const url = "https://lca.realware.tech/realgreensupplier/createActualValue"
    return await axios.post(url, obj, options)
}


export async function SupplierCreationInOEM(obj) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = baseUrl + "SupplierCreationInOEM"
    return await axios.post(url, obj, options)
}

export async function getSupplierListUnderOEM() {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = baseUrl + "getSupplierListUnderOEM"
    return await axios.get(url, options)
}

export async function supplierListUnderMaterial() {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = baseUrl + "supplierListUnderMaterial"
    return await axios.get(url, options)
}


export async function getSupplierListForEveryMaterial(obj) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig, params: obj };
    const url = "https://lca.realware.tech/realgreenmaterial/getSupplierListForEveryMaterial"
    return await axios.get(url, options)
}


export async function supplierNameUnderSupplierId(supplierId) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = baseUrl + "Rr_Get_Supplier_Name_Under_Supplier_Id"
    return await axios.post(url, supplierId, options)
}

export async function materialCreate(obj) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = baseUrl + "Rr_material_Create"
    return await axios.post(url, obj, options)
}

export async function getMaterialList() {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = baseUrl + "getMaterialList"
    return await axios.get(url, options)
}


export async function Rr_BOQ_List_Under_Supplier(obj) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = "https://lca.realware.tech/realgreenmaterial/boqListUnderSupplier"
    return await axios.post(url, obj, options)
}


export async function Rr_Carbon_Emission_List_Under_Supplier(obj) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = "https://lca.realware.tech/realgreenmaterial/carbonEmissionListUnderSupplier"
    return await axios.post(url, obj, options)
}


export async function uploads(formData, uploadType) {
    const basicHeaders = {
        "Content-Type": "multipart/form-date",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    };
    const options = { headers: basicHeaders };
    let type = '';
    if (uploadType === 'BOQ') {
        type = 'Rr_BOQ_Excel_Upload_Preview'
    }
    else if (uploadType === 'Carbon Emission') {
        type = 'CarbonEmissionBulkUpload'
    }
    else if (uploadType === 'OEMBOQ') {
        type = 'OemBoqUpload'
    }
    else if (uploadType === 'Water Consumption') {
        type = 'waterConsumptionExcelUploadPreview'
    }
    const url = ' https://api.generic.innovation.realware.tech/features/bulk-upload-validate/Sustainability/' + type;
    return await axios.post(url, formData, options)
}


export async function excelUploadPreview(data, type) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = 'https://lca.realware.tech/realgreenupload/' + type
    return await axios.post(url, data, options)
}

export async function boqExcelUploadSubmit(data) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = 'https://lca.realware.tech/realgreenupload/boqExcelUploadSubmit'
    return await axios.post(url, data, options)
}

export async function carbonEmissionExcelUploadSubmit(data) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = 'https://lca.realware.tech/realgreenupload/carbonEmissionExcelUploadSubmit'
    return await axios.post(url, data, options)
}

export async function waterConsumptionExcelUploadSubmit(data) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = 'https://lca.realware.tech/realgreenupload/waterConsumptionExcelUploadSubmit'
    return await axios.post(url, data, options)
}

export async function oemBoqUploadSubmit(data) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = 'https://lca.realware.tech/realgreenupload/oemBoqUploadSubmit '
    return await axios.post(url, data, options)
}

export async function categoryInfoUnderSetGoal() {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = "https://lca.realware.tech/realgreenmaterial/categoryInfoUnderSetGoal"
    return await axios.get(url, options)
}

export async function Rr_Category_Under_Product_List(obj) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = "https://lca.realware.tech/realgreenmaterial/categoryUnderProductList"
    return await axios.post(url, obj, options)
}

export async function Rr_Every_Component_Wise_BOQ_List(obj) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = baseUrl + "Rr_Every_Component_Wise_BOQ_List"
    return await axios.post(url, obj, options)
}

export async function setGoalCreate(obj) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = baseUrl + "setGoalCreate"
    return await axios.post(url, obj, options)
}

export async function setGoalUpdate(obj) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = baseUrl + "setGoalUpdate"
    return await axios.post(url, obj, options)
}

export async function Scope3CategoryList() {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = baseUrl + "Scope3CategoryList"
    return await axios.get(url, options)
}

export async function goalVsActualReport(obj) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = "https://lca.realware.tech/realgreenmaterial/goalVsActualReportBySupplier"
    return await axios.post(url, obj, options)
}

export async function goalVsActualReportByOEM(obj) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = "https://lca.realware.tech/realgreenmaterial/goalVsActualReportByOEM"
    return await axios.post(url, obj, options)
}

export async function getIndustryInfo() {
    const headerConfig = {
        "Content_Type": "application/json",
        // Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = "https://lca.realware.tech/realgreenchickenPasta/getIndustryInfo"
    return await axios.get(url, options)
}

export async function getCountryInfo() {
    const headerConfig = {
        "Content_Type": "application/json",
        // Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = "https://lca.realware.tech/realgreenchickenPasta/getCountryInfo"
    return await axios.get(url, options)
}

export async function GoalsInEveryIndustry(obj) {
    const headerConfig = {
        "Content_Type": "application/json",
        // Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = "https://lca.realware.tech/realgreenchickenPasta/substainabilityGoalsInEveryIndustry"
    return await axios.post(url, obj, options)
}
export async function getActualAndBaseValueSumInEveryProduct(obj) {
    const headerConfig = {
        "Content_Type": "application/json",
        // Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = "https://lca.realware.tech/realgreenchickenPasta/getActualAndBaseValueSumInEveryProduct"
    return await axios.post(url, obj, options)
}
export async function updateActualValue(obj) {
    const headerConfig = {
        "Content_Type": "application/json",
        // Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = "https://lca.realware.tech/realgreenchickenPasta/updateActualValue"
    return await axios.post(url, obj, options)
}

export async function filterByOEMs() {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = baseUrl + "filterByOEMs"
    return await axios.get(url, options)
}

export async function filterByFinishedComponents(obj) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = baseUrl + "filterByFinishedComponents"
    return await axios.post(url, obj, options)
}

export async function Rr_Component_List_Under_Material() {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = baseUrl + "Rr_Component_List_Under_Material"
    return await axios.get(url, options)
}

export async function setGoalFinalSubmit(obj) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = baseUrl + "setGoalFinalSubmit"
    return await axios.post(url, obj, options)
}
export async function setGoalPrePopulate(obj) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = baseUrl + "setGoalPrePopulate"
    return await axios.post(url, obj, options)
}
export async function upStreamAndDownStreamAvg(manufacturingUnit) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    let options
    if (manufacturingUnit) {
        options = { headers: headerConfig, params: { manufacturingUnit: manufacturingUnit } };
    }
    else {
        options = { headers: headerConfig };
    }
    const url = "https://lca.realware.tech/realgreendashboard/upStreamAndDownStreamAvg"
    return await axios.get(url, options)
}
export async function upStreamAndDownStreamActivities(manufacturingUnit) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    let options
    if (manufacturingUnit) {
        options = { headers: headerConfig, params: { manufacturingUnit: manufacturingUnit } };
    }
    else {
        options = { headers: headerConfig };
    }
    const url = "https://lca.realware.tech/realgreendashboard/upStreamAndDownStreamActivities"
    return await axios.get(url, options)
}
export async function top3SuppliersUnderScope3Emission(manufacturingUnit) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    let options
    if (manufacturingUnit) {
        options = { headers: headerConfig, params: { manufacturingUnit: manufacturingUnit } };
    }
    else {
        options = { headers: headerConfig };
    }
    const url = "https://lca.realware.tech/realgreendashboard/top3SuppliersUnderScope3Emission"
    return await axios.get(url, options)
}
export async function top5ComponentsUnderScope3Emission(manufacturingUnit) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    let options
    if (manufacturingUnit) {
        options = { headers: headerConfig, params: { manufacturingUnit: manufacturingUnit } };
    }
    else {
        options = { headers: headerConfig };
    }
    const url = "https://lca.realware.tech/realgreendashboard/top5ComponentsUnderScope3Emission"
    return await axios.get(url, options)
}
export async function monthOnMonthEmissions(manufacturingUnit) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    let options
    if (manufacturingUnit) {
        options = { headers: headerConfig, params: { manufacturingUnit: manufacturingUnit } };
    }
    else {
        options = { headers: headerConfig };
    }
    const url = "https://lca.realware.tech/realgreendashboard/monthOnMonthEmissions"
    return await axios.get(url, options)
}
export async function getCo2EmissionUnderComposition(obj) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig, params: obj };
    const url = "https://lca.realware.tech/realgreendashboard/getCo2EmissionUnderComposition"
    return await axios.get(url, options)
}

export async function everyComponentWiseCo2eComposition(obj) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = "https://lca.realware.tech/realgreendashboard/everyComponentWiseCo2eComposition"
    return await axios.post(url, obj, options)
}

export async function sankeyDiagramForEveryComponent(obj) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = "https://lca.realware.tech/realgreendashboard/sankeyDiagramForEveryComponent"
    return await axios.post(url, obj, options)
}


export async function realgreenoemOnboard(obj) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = "https://lca.realware.tech/realgreenoemOnboard/createOem"
    return await axios.post(url, obj, options)
}


export async function getActivityList(obj) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig, params: obj };
    const url = "https://lca.realware.tech/realgreenoemOnboard/getActivityList"
    return await axios.get(url, options)
}

export async function boqListInOEM() {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = "https://lca.realware.tech/realgreenmaterial/boqListInOEM"
    return await axios.get(url, options)
}


export async function activityList() {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = "https://lca.realware.tech/realgreenoemOnboard/getAllComponents"
    return await axios.get(url, options)
}

export async function getActivityListDetails(obj) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig, params: obj };
    const url = "https://lca.realware.tech/realgreenoemOnboard/getLinkedComponents"
    return await axios.get(url, options)
}

export async function getDatesFromSetGoal() {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    let options = { headers: headerConfig };
    const url = "https://lca.realware.tech/realgreendashboard/getDatesFromSetGoal"
    return await axios.get(url, options)
}

export async function emissionTypeDropdownList(obj) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = "https://lca.realware.tech/realgreenreports/emissionTypeDropdownList"
    return await axios.post(url, obj, options)
}


export async function supplierListOnEmission(obj) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = "https://lca.realware.tech/realgreenreports/supplierListOnEmission"
    return await axios.post(url, obj, options)
}


export async function categoryListOnEmission(obj) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = "https://lca.realware.tech/realgreenreports/categoryListOnEmission"
    return await axios.post(url, obj, options)
}

export async function listOfAllCategoriesInOpenLCA() {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = "https://lca.realware.tech/realgreenreports/listOfAllCategoriesInOpenLCA"
    return await axios.get(url, options)
}

export async function filterByFinishComponentEmission(oem) {
    const headerConfig = {
        "Content_Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig };
    const url = baseUrl + "filterByFinishComponentEmission"
    return await axios.post(url, oem, options)
}
