import { useState } from "react";
import { Tabs, Tab } from 'react-bootstrap-tabs';
import ExcelUploader from "../excelUpload/excelUpload";
import OEMBOQUploadsPreview from "./OEMBOQUploadPreview";
import OEMBOQList from "./OEMBOQList"
// import CarbonEmissionUploadsPreview from "..Supplier/CarbonEmissionUploadsPreview";
const OEMBOQ = () => {
    const [showPreviewTable, setShowPreviewTable] = useState(false);
    const [data, setData] = useState([]);
    const [tabKey, setTabKey] = useState(0); // State to keep track of the tab key

    const handleTabChange = (newKey) => {
        setTabKey(newKey); // Update the tab key when the tab changes
        setShowPreviewTable(false);
        setData([]);
    };
    const responseData = (data) => {
        if (data.status.code === "SUCCESS") {
            setData(data.primary.data);
            setShowPreviewTable(true);
        }
    }
    return (
        <div className="farmMaster">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">Bill of Quantity (BOQ)</h2>
                </div>
            </div>
            <div className="card">
                <div class="card-body">
                    <Tabs activeHeaderStyle={{ background: 'transparent' }} onSelect={() => handleTabChange(Date.now())}>
                        <Tab label="Upload BOQ" eventKey="Upload BOQ">
                            <div className="row">
                                <div className="col">
                                    <ExcelUploader key={tabKey} uploadType='OEMBOQ' filePath='/OEMBOQ.xlsx' responseDataSend={responseData}></ExcelUploader>
                                </div>
                            </div>
                            {showPreviewTable && (<OEMBOQUploadsPreview tableData={data}></OEMBOQUploadsPreview>)}
                        </Tab>
                        <Tab label="BOQ List" eventKey="Upload Carbon Emission">
                            <OEMBOQList></OEMBOQList>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

export default OEMBOQ;