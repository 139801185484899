import React, { useState, useEffect, useRef } from "react";
import Calculator from "./Calculator";
import InfoBox from "./infoBox";
import { NavLink } from "react-router-dom";
import moment from "moment";
import PreCheckDetails from "./preCheckDetails"
import { getIndustryTypeList, getProductComponentsUnderIndustryType } from "../const"

const Industries = () => {
    const [activeBox, setActiveBox] = useState('Electronics');
    const [dropdownData, setDropdownData] = useState([]);
    const [componentsData, setComponentsData] = useState([]);
    const [sumIdealValue, setSumIdealValue] = useState(0);
    const handleBoxClick = (boxName) => {
        setActiveBox(boxName);
        getIndustryTypeList({ industryName: boxName }).then(res => {
            if (res.status === 200 && res.data.status.code === 'SUCCESS') {
                setDropdownData(res.data.primary)
            }
        })
    }

    const onHandleChangeCategorie = (e) => {
        // console.log("Event", e.target.value);
        getProductComponentsUnderIndustryType({ productId: e.target.value }).then(res => {
            if (res.status === 200 && res.data.status.code === 'SUCCESS') {
                setComponentsData(res.data.primary);
                const sumIdealValue = res.data.primary.reduce((sum, item) => sum + item.idealValue, 0);
                setSumIdealValue(sumIdealValue)
            }
        })
    }

    // useEffect(() => {
    //     getIndustryTypeList({ industryName: "Electronics" }).then(res => {
    //         if (res.status === 200 && res.data.status.code === 'SUCCESS') {
    //             setDropdownData(res.data.primary)
    //         }
    //     })
    // }, [])

    return (
        <>
            <div class="wrapper">
                <div>
                    <nav className="p-0 main-header navbar-fixed-top navbar navbar-expand-md navbar-light navbar-white">
                        <div class="container-fluid" style={{ paddingLeft: 20, paddingRight: 20 }}>
                            <NavLink exact to="/" class="navbar-brand">
                                <img src="/dist/img/logo.png" height="30" alt="RealGreen Logo"></img>&nbsp;
                            </NavLink>
                        </div>
                    </nav>
                </div>
                <div className="myappcontent">
                    <div className="container-fluid">
                        <div className="dashboard">
                            <div className="row" style={{ marginBottom: 15 }}>
                                <div className="col-md-12" style={{ justifyContent: "center", display: "flex" }}>
                                    <h1 class="m-0" style={{ fontSize: "28px" }}>
                                        <span><strong style={{ color: "green" }}>Know more</strong></span> about our approach to help achieve your <span><strong style={{ color: "green" }}>sustainability goals</strong></span>…
                                    </h1>
                                    {/* <p class="ml-2"> Select an industry from below categories to move towards more sustainable insights.</p> */}
                                </div>
                            </div>
                            {/* <div className="row">
                                <InfoBox
                                    iconName="files"
                                    title="Electronics"
                                    onClick={() => handleBoxClick('Electronics')} // Bar and Map
                                    isActive={activeBox === 'Electronics'}
                                />
                                <InfoBox
                                    iconName="time"
                                    title="Automotive"
                                    onClick={() => handleBoxClick('Automotive')} // Bar and Map
                                    isActive={activeBox === 'Automotive'}
                                />
                                <InfoBox
                                    iconName="truck"
                                    title="Food & Beverages"
                                    onClick={() => handleBoxClick('FoodBeverages')} //widgets and Bar and no Map
                                    isActive={activeBox === 'FoodBeverages'}
                                />
                                <InfoBox
                                    iconName="dashboard"
                                    title="Oil & Gas"
                                    onClick={() => handleBoxClick('OilGas')} // Widgets and Map
                                    isActive={activeBox === 'OilGas'}
                                />
                            </div> */}
                            {/* <div className="row" style={{ justifyContent: "end" }}>
                                <div className='col-md-3 mb-0'>
                                    <div className="form-group">
                                        <select class="form-control form-control-sm" name="categorie"
                                            onChange={(e) => { onHandleChangeCategorie(e) }}>
                                            <option value="">- Select industry categorie -</option>
                                            {dropdownData.map(item => (
                                                <option
                                                    key={item.productId}
                                                    value={item.productId}
                                                >
                                                    {item.productName}
                                                </option>
                                            ))}
                                        </select>

                                    </div>
                                </div>
                            </div> */}
                            <div className="row " style={{ marginTop: "5%" }}>
                                {/* {componentsData.length !== 0 && */}
                                <>
                                    <div className="col-md-6">
                                        <img src="/dist/img/img.png" alt="RealGreen Logo"></img>&nbsp;
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mt-2">


                                            {/* <div>
                                                <h1 class="m-0" style={{ fontSize: "25px", fontWeight: "600", display: 'inline', textTransform: "none", color: "green" }}>
                                                    Getting Started
                                                </h1>
                                                <p class="ml-1" style={{ fontSize: "18px" }}> You're Only <span style={{ color: "green", fontWeight: "bold", fontSize: "19px" }}>4</span> steps away from getting started on RealGreen.</p>
                                            </div> */}
                                            {/* <br></br> */}
                                            <PreCheckDetails></PreCheckDetails>
                                        </div>


                                    </div>
                                </>
                                {/* } */}
                                {
                                    // componentsData.length === 0 &&
                                    //     <div className="col-md-12">
                                    //         <div className="card">
                                    //             <div className="card-body">
                                    //                 <div style={{ display: "grid", justifyContent: "center", fontSize: "medium", fontWeight: "600" }}>
                                    //                     {/* <div className="row"> */}
                                    //                     <span >Please Select the industry categorie</span>
                                    //                     {/* </div> */}
                                    //                 </div>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                }
                                {/* <div className="col-md-3"></div> */}
                            </div>
                            {/* <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="form-inline">
                                                <h2 class="m-0  text-dark" style={{ display: 'inline', textTransform: "none" }}>Scope 3 kg CO<sub>2</sub> e</h2>
                                            </div>
                                            {componentsData.length !== 0 &&
                                                <Calculator components={componentsData} sumOfIdealValue={sumIdealValue}></Calculator>
                                            }
                                            {componentsData.length === 0 &&
                                                <div>
                                                    <div className="row" style={{ display: "grid", justifyContent: "center", fontSize: "medium", fontWeight: "600" }}>
                                                        <span >Please Select the industry categorie</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div>
                    <footer class="main-footer">
                        Copyright &copy; 2022-{moment().format('YYYY')} <a href="#">RealVariable</a>. All rights reserved.
                    </footer>
                </div>
            </div>
        </>
    )
}


export default Industries;
