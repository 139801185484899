import WaterfallChart from "./WaterfallChart"
import DataTable from "react-data-table-component";
import React, { useState, useEffect } from "react";
import { goalVsActualReportByOEM, Rr_Every_Component_Wise_BOQ_List, filterByFinishComponentEmission } from "../const"
import moment from 'moment';
import { useLoading, Bars } from '@agney/react-loading';

const Forecast = ({ enteredManufacturedUnit }) => {

    const [data, setData] = useState([]);
    const [offcanvas, setoffcanvas] = useState(false);
    const [datasubComp, setdatasubComp] = useState([]);
    const [componentDropdownData, setComponentDropdownData] = useState([]);
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="22" color="#333" />,
    })
    const [showLoader, setShowLoader] = useState(false);
    const columns = [
        sessionStorage.getItem("User") !== 'Admin' ?
            {
                name: 'OEM',
                selector: row => row.OEM,

            } : {
                name: 'Supplier',
                selector: row => row.supplierName,
                minWidth: "200px"


            },
        {
            name: 'Category Name',
            selector: row => row.categoryName,
            minWidth: "300px"
        },
        {
            name: 'Activity Type',
            selector: row => row.activityType,
            minWidth: "150px"
        },
        {
            name: 'Component Name',
            selector: row => row.componentName,
            minWidth: "250px"
        },
        // {
        //     name: 'BOQ',
        //     selector: row => row.bo,
        //     cell: row => (
        //         <>
        //             <span
        //                 style={{ fontSize: 14, marginLeft: 10, color: "blue", cursor: "pointer" }}
        //                 onClick={() => Overlay(row)} // Make sure to use an arrow function here
        //             >BOQ</span>
        //         </>
        //     ),


        // },
        {
            name: 'Quantity',
            selector: row => row.quantity,
            minWidth: "40px"
        },
        {
            name: 'Base Line Co2 e (in kg)',
            selector: row => row.baseLineKg,
        },
        {
            name: 'Goal Value (per unit)',
            selector: row => formatNumber(row.setGoalPerUnitInKG),
        },
        {
            name: 'Actual Co2 e (in kg per unit)',
            selector: row => formatNumber(row.actualCo2eEmission),
            cell: row => (
                <>
                    <span
                        style={{ fontSize: 14, marginLeft: 10, color: "blue", cursor: "pointer" }}
                        onClick={() => Overlay(row)} // Make sure to use an arrow function here
                    >{formatNumber(row.actualCo2eEmission.toFixed(2))}</span>
                </>
            ),
        },
        {
            name: 'Goal vs Actual (in %)',
            selector: row => formatNumber(row.percentage),
            minWidth: "150px",
            cell: row => (
                <>
                    <span className="ml-4">{formatNumber(row.percentage)}</span>
                    {
                        row.status === "decrease" &&
                        (<img className="mb-1 ml-2" style={{ height: "18px" }} src="/dist/img/decrement.png" />)
                    }
                    {
                        row.status === "increate" &&
                        (<img className="mb-1 ml-2" style={{ height: "18px" }} src="/dist/img/increment.png" />)
                    }
                </>
            )
        },
        {
            name: 'From Date',
            selector: row => moment(row.fromDate).format('DD-MMM-YYYY'),
            minWidth: "120px"
        },
        {
            name: 'To Date',
            selector: row => moment(row.toDate).format('DD-MMM-YYYY'),
            minWidth: "120px"
        }
    ];
    const columnsSubcomp = [
        {
            name: 'Supplier Name',
            selector: row => row.supplierName,
            sortable: true
        },
        {
            name: 'Material',
            selector: row => row.materialComponentName,
            sortable: true
        },
        // {
        //     name: 'Base Line qty',
        //     selector: row => row.quantity,
        //     sortable: true
        // },
        {
            name: 'Base Line kg CO₂ e',
            selector: row => row.baseLineKg,
            sortable: true
        },
        {
            name: 'Actual qty',
            selector: row => row.actualQuantity,
            sortable: true
        },
        {
            name: 'Actual kg CO₂ e',
            selector: row => row.actualCo2eEmission.toFixed(2),
            sortable: true
        }
    ];

    useEffect(() => {
        setShowLoader(true);
        let obj
        if (enteredManufacturedUnit) {
            obj = {
                oem: "",
                componentName: "",
                manufacturingUnit: enteredManufacturedUnit
            }
        }
        else {
            obj = {
                oem: "",
                componentName: ""
            }
        }
        goalVsActualReportByOEM(obj).then(res => {
            if (res.status === 200 && res.data.status.code === 'SUCCESS') {
                setData(res.data.primary);
                setShowLoader(false);
            }
        })
        filterByFinishComponentEmission({ OEM: "" }).then(resData => {
            if (resData.status === 200 && resData.data.status.code === 'SUCCESS') {
                console.log("resData", resData)
                setComponentDropdownData(resData.data.primary);
            }
        })
    }, [enteredManufacturedUnit])


    const formatNumber = (number) => {
        return parseFloat(number).toLocaleString('en', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };

    const Overlay = (row) => {
        Rr_Every_Component_Wise_BOQ_List({ componentName: row.componentName }).then(resp => {
            if (resp.status === 200 && resp.data[0].status.code === 'SUCCESS') {
                setdatasubComp(resp.data[0].primary)
            }
        })
        setoffcanvas(true);
    }

    const closeSideOverlay = () => {
        setoffcanvas(false);
    };

    const handleSelectChange = (value) => {
        console.log("Selected value: ", value);
        setShowLoader(true);
        let obj
        if (enteredManufacturedUnit) {
            obj = {
                oem: "",
                componentName: value,
                manufacturingUnit: enteredManufacturedUnit
            }
        }
        else {
            obj = {
                oem: "",
                componentName: value
            }
        }
        goalVsActualReportByOEM(obj).then(res => {
            if (res.status === 200 && res.data.status.code === 'SUCCESS') {
                setData(res.data.primary);
                setShowLoader(false);
            }
        })
    };

    return (
        <>
            {/* <div className='taskView' >
                <div className='row'>
                    <div className='col-md-12'>
                        <WaterfallChart />
                    </div>
                </div>
            </div> */}

            <div className='taskView' >
                <div className='row'>
                    <div className='col-md-12'>
                        {/* <h2 className="text-dark">Goal Vs Actual Report</h2> */}
                        <div className='card'>
                            <div className='card-body'>
                                <div className="row">
                                    <div className="col-md-12 mb-0" style={{}}>
                                        <div className="row">
                                            <div className="col-md-1" style={{ textAlign: 'left', marginTop: 5 }}><strong>Filter By: </strong></div>
                                        </div>
                                        <div className="row mb-0">
                                            <div className='col-md-2'>
                                                <div className="form-group">
                                                    <select class="form-control form-control-sm" name="farmer"
                                                        onChange={(e) => handleSelectChange(e.target.value)}
                                                    >
                                                        <option value="">-Select Component-</option>
                                                        {
                                                            componentDropdownData.map(item => (
                                                                <option key={item.componentName} value={item.componentName}>{item.componentName}</option>
                                                            ))
                                                        }
                                                    </select>

                                                </div>
                                            </div>
                                            <div className="col mb-0">
                                                <button style={{ marginTop: 0 }} className="btn btn-sm btn-primary">Submit</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <DataTable
                            columns={columns}
                            data={data}
                            defaultSortField="taskID"
                            pagination
                            dense
                            selectableRowsHighlight='true'
                            compact
                            highlightOnHover='true'
                            striped
                        />
                    </div>
                </div>

                <div id='sideOverlay' className={`side-overlay ${offcanvas ? 'open' : ''}`} style={{ boxShadow: "0 0 7px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 26%)", width: "70%" }}>
                    <div className="overlay-content">
                        <i class="fas fa-times-circle close-button" aria-hidden="true" onClick={closeSideOverlay} style={{ fontSize: 20 }}></i>
                        {/* <button className="close-button" >Close</button> */}
                        <div className='row' style={{ marginTop: "2%", marginLeft: "3%", marginRight: "3%" }}>
                            <div className='col-md-12'>
                                <div className='card-body'>
                                    <div className='title row' style={{ justifyContent: "left" }}>
                                        <h2 className='p-1 ml-1 m-2 text-dark'>
                                            BOQ
                                        </h2>
                                    </div>
                                    <div className='row mt-4 sideOverlaySummary'>
                                        <div className='col-6'>
                                            <strong>Sub Assembly Components	: </strong> {datasubComp.length !== 0 && (
                                                datasubComp[0].componentName
                                            )}
                                        </div>
                                    </div>
                                    <div className='row mt-3' style={{ border: '1px solid black' }}>
                                        <div className='col-12'>
                                            <DataTable
                                                columns={columnsSubcomp}
                                                data={datasubComp}
                                                pagination
                                                dense
                                                selectableRowsHighlight='true'
                                                compact
                                                highlightOnHover='true'
                                                striped
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {
                showLoader ?
                    <section {...containerProps
                    } style={{ "margin-top": "10%", display: 'inline' }}>
                        {indicatorEl}
                    </section > : ""
            }
        </>
    )
}

export default Forecast;