import WaterfallChart from "./WaterfallChart"
import DataTable from "react-data-table-component";
import React, { useState, useEffect } from "react";
import { emissionTypeDropdownList, supplierListOnEmission, categoryListOnEmission, listOfAllCategoriesInOpenLCA } from "../const"
import moment from 'moment';
import { useLoading, Bars } from '@agney/react-loading';

const Reports = ({ enteredManufacturedUnit }) => {

    const [data, setData] = useState([]);
    const [selectedDropdownValue, setSelectedDropdownValue] = useState();
    const [supplierDropdownData, setSupplierDropdownData] = useState([]);
    const [categoryNameDropdownData, setCategoryNameDropdownData] = useState([]);
    const [dropdownOptions, setDropDropdownOptions] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState('');

    const [selectedCategoryName, setSelectedCategoryName] = useState('');


    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="22" color="#333" />,
    })
    const [showLoader, setShowLoader] = useState(false);
    const columns = [
        sessionStorage.getItem("User") !== 'Admin' ?
            {
                name: 'OEM',
                selector: row => row.OEM,

            } : {
                name: 'Supplier',
                selector: row => row.supplierName,
                minWidth: "200px"


            },
        {
            name: 'Category Name',
            selector: row => row.categoryName,
            minWidth: "300px"
        },
        {
            name: 'Activity Type',
            selector: row => row.activityType,
            minWidth: "150px"
        },
        {
            name: 'Component Name',
            selector: row => row.componentName,
            minWidth: "250px"
        },
        {
            name: 'Base Line Co2 e (in kg)',
            selector: row => formatNumber(row.baseLineKg),
        },
        {
            name: 'Actual Co2 e (in kg per unit)',
            selector: row => formatNumber(row.actualCo2eEmission),

        },
        {
            name: 'deviation (in %)',
            selector: row => formatNumber(row.percentage),
            minWidth: "150px",
            cell: row => (
                <>
                    <span className="ml-4">{formatNumber(row.percentage)}</span>
                    {
                        row.status === "decrease" &&
                        (<img className="mb-1 ml-2" style={{ height: "18px" }} src="/dist/img/decrement.png" />)
                    }
                    {
                        row.status === "increate" &&
                        (<img className="mb-1 ml-2" style={{ height: "18px" }} src="/dist/img/increment.png" />)
                    }
                </>
            )
        },
        {
            name: 'From Date',
            selector: row => moment(row.fromDate).format('DD-MMM-YYYY'),
            minWidth: "120px"
        },
        {
            name: 'To Date',
            selector: row => moment(row.toDate).format('DD-MMM-YYYY'),
            minWidth: "120px"
        }
    ];


    useEffect(() => {
        listOfAllCategoriesInOpenLCA().then(respdata => {
            if (respdata.status === 200 && respdata.data.status.code === 'SUCCESS') {
                setDropDropdownOptions(respdata.data.primary)
                setSelectedDropdownValue(respdata.data.primary[0].openLCACategoryName)
                getreport(respdata.data.primary[0].openLCACategoryName)
            }
        })
    }, [enteredManufacturedUnit])

    const getreport = (data) => {
        let obj
        if (enteredManufacturedUnit) {
            obj = {
                consumptionType: data,
                manufacturingUnit: enteredManufacturedUnit
            }
        }
        else {
            obj = {
                consumptionType: data
            }
        }
        emissionTypeDropdownList(obj).then(resp => {
            if (resp.status === 200 && resp.data.status.code === 'SUCCESS') {
                setData(resp.data.primary);
                setSelectedDropdownValue(data);
            }
        })

        supplierListOnEmission({ emissionType: data }).then(res => {
            if (res.status === 200 && res.data.status.code === 'SUCCESS') {
                setSupplierDropdownData(res.data.primary);
            }
        })

        categoryListOnEmission({ emissionType: data }).then(res => {
            if (res.status === 200 && res.data.status.code === 'SUCCESS') {
                setCategoryNameDropdownData(res.data.primary);
            }
        })
    }


    const formatNumber = (number) => {
        return parseFloat(number).toLocaleString('en', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };


    const handelSelectionChanged = (e) => {
        setSelectedDropdownValue(e.target.value)
        setCategoryNameDropdownData([]);
        setSupplierDropdownData([]);
        console.log("data", e.target.value)
        let obj
        if (enteredManufacturedUnit) {
            obj = {
                consumptionType: e.target.value,
                manufacturingUnit: enteredManufacturedUnit
            }
        }
        else {
            obj = {
                consumptionType: e.target.value
            }
        }
        emissionTypeDropdownList(obj).then(resp => {
            if (resp.status === 200 && resp.data.status.code === 'SUCCESS') {
                setData(resp.data.primary)
            }
        })

        supplierListOnEmission({ emissionType: e.target.value }).then(res => {
            if (res.status === 200 && res.data.status.code === 'SUCCESS') {
                setSupplierDropdownData(res.data.primary);
            }
        })

        categoryListOnEmission({ emissionType: e.target.value }).then(res => {
            if (res.status === 200 && res.data.status.code === 'SUCCESS') {
                setCategoryNameDropdownData(res.data.primary);
            }
        })
    }

    const handleInputChangeSupplier = (e) => {
        setSelectedSupplier(e.target.value)
        let obj
        if (enteredManufacturedUnit) {
            obj = {
                consumptionType: selectedDropdownValue,
                manufacturingUnit: enteredManufacturedUnit,
                createdBy: e.target.value
            }
        }
        else {
            obj = {
                consumptionType: selectedDropdownValue,
                createdBy: e.target.value
            }
        }
        emissionTypeDropdownList(obj).then(resp => {
            if (resp.status === 200 && resp.data.status.code === 'SUCCESS') {
                setData(resp.data.primary)
            }
        })

    }

    const handleInputChangeCategoryName = (e) => {
        setSelectedCategoryName(e.target.value)
        let obj
        if (enteredManufacturedUnit) {
            obj = {
                consumptionType: selectedDropdownValue,
                manufacturingUnit: enteredManufacturedUnit,
                createdBy: selectedSupplier,
                categoryName: e.target.value
            }
        }
        else {
            obj = {
                consumptionType: selectedDropdownValue,
                createdBy: selectedSupplier,
                categoryName: e.target.value
            }
        }
        emissionTypeDropdownList(obj).then(resp => {
            if (resp.status === 200 && resp.data.status.code === 'SUCCESS') {
                setData(resp.data.primary)
            }
        })
    }



    return (
        <>
            <div className='taskView' >
                <div className='row'>
                    <div className='col-md-12'>
                        {/* <h2 className="text-dark">Goal Vs Actual Report</h2> */}
                        <div className='card'>
                            <div className='card-body'>
                                <div className="row">
                                    <div className="col-md-3 mb-0">
                                        <div className="row">
                                            <div className="col" style={{ textAlign: 'left', marginTop: 5 }}><strong>Impact Category: </strong></div>
                                        </div>
                                        <div className="row mb-0">
                                            <div className='col'>
                                                <div className="form-group">
                                                    <select class="form-control form-control-sm" name="farmer" value={selectedDropdownValue} onChange={(e) => { handelSelectionChanged(e) }}>
                                                        {
                                                            dropdownOptions.map((item) => (
                                                                <option value={item.openLCACategoryName}>{item.openLCACategoryName}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-md-3 mb-0">
                                        <div className="row">
                                            <div className="col" style={{ textAlign: 'left', marginTop: 5 }}><strong>Supplier Name: </strong></div>
                                        </div>
                                        <div className="row mb-0">
                                            <div className='col'>
                                                <div className="form-group">
                                                    <select
                                                        class="form-control form-control-sm" name="supplier"
                                                        placeholder="Select"
                                                        onChange={(e) => handleInputChangeSupplier(e)}
                                                    >
                                                        <option value="">-Select-</option>
                                                        {supplierDropdownData.map(item => (
                                                            <option
                                                                key={item.supplierId}
                                                                value={item.supplierId}
                                                            >
                                                                {item.supplierId} - {item.supplierName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-md-3 mb-0">
                                        <div className="row">
                                            <div className="col" style={{ textAlign: 'left', marginTop: 5 }}><strong>Category Name: </strong></div>
                                        </div>
                                        <div className="row mb-0">
                                            <div className='col'>
                                                <div className="form-group">
                                                    <select
                                                        class="form-control form-control-sm" name="CategoryName"
                                                        placeholder="Select"
                                                        onChange={(e) => handleInputChangeCategoryName(e)}
                                                    >
                                                        <option value="">-Select-</option>
                                                        {categoryNameDropdownData.map(item => (
                                                            <option
                                                                key={item.categoryName}
                                                                value={item.categoryName}
                                                            >
                                                                {item.categoryName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {/* <div className="col mb-0">
                                                <button style={{ marginTop: 0 }} className="btn btn-sm btn-primary">Submit</button>
                                            </div> */}
                                </div>
                            </div>
                        </div>
                        <DataTable
                            columns={columns}
                            data={data}
                            defaultSortField="taskID"
                            pagination
                            dense
                            selectableRowsHighlight='true'
                            compact
                            highlightOnHover='true'
                            striped
                        />
                    </div>
                </div>



            </div>

            {
                showLoader ?
                    <section {...containerProps
                    } style={{ "margin-top": "10%", display: 'inline' }}>
                        {indicatorEl}
                    </section > : ""
            }
        </>
    )
}

export default Reports;