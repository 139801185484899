import React, { useState, useRef, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import SupplierMasterList from "./SupplierMasterList";
import SupplierMaster from "./SupplierMaster"

const Supplier = () => {
    const [editMode, setEditMode] = useState(false);
    const [editSupplierId, setEditSupplierId] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const [createTabKey, setCreateTabKey] = useState(0); // New state variable for the key of the "Create Supplier Master" tab

    const handleEdit = (supplierId) => {
        // console.log("supplierId:::", supplierId);
        setEditMode(true);
        setEditSupplierId(supplierId);
        setActiveTab(0); // Set active tab index to 0
        setCreateTabKey(createTabKey + 1); // Update the key of the "Create Supplier Master" tab to force re-rendering
    };

    const handleTabSelect = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    return (
        <div className="planning">
            <div className="row mb-2">
                <div className="col-sm-6">
                    <h2 className="m-0 text-dark">Supplier Master</h2>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <Tabs activeTab={activeTab} onSelect={handleTabSelect} activeHeaderStyle={{ background: 'transparent' }}>
                        <Tab label="Create Supplier Master" key={createTabKey}>
                            <SupplierMaster />
                        </Tab>
                        <Tab label="Suppliers List">
                            <SupplierMasterList handleEdit={handleEdit} />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}

export default Supplier;


