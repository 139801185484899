import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { getIndustryInfo, getCountryInfo, getIndustryTypeList, GoalsInEveryIndustry } from "../const";
import Swal from 'sweetalert2';

const PreCheckDetails = () => {
    const [step, setStep] = useState(1); // State to track current step
    const totalSteps = 5; // Total number of steps
    const [completedSteps, setCompletedSteps] = useState({}); // State to track completed steps
    const history = useHistory();
    const remainingSteps = totalSteps - step;
    const [industryList, setIndustryList] = useState([]);
    const [industryTypeList, setIndustryTypeList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [formData, setFormData] = useState({
        industry: "",
        productId: "",
        countryId: "",
        renewableEnergy: "",
        nonRenewableEnergy: "",
        manufacturingUnit: "",
        refurbished: "",
        disposed: ""
    });

    useEffect(() => {
        getIndustryInfo().then(industryResp => {
            if (industryResp.status === 200 && industryResp.data.status.code === 'SUCCESS') {
                setIndustryList(industryResp.data.primary);
            }
        });
        getCountryInfo().then(countryResp => {
            if (countryResp.status === 200 && countryResp.data.status.code === 'SUCCESS') {
                setCountryList(countryResp.data.primary);
            }
        });
    }, []);

    const handleNext = () => {
        if (step < totalSteps && validateStep()) {
            setStep(step + 1); // Move to the next step
        } else if (step === totalSteps && validateStep()) {
            // Handle submission when all steps are completed
            const formDataWithNumbers = {
                ...formData,
                // industry: parseFloat(formData.industry),
                // productId: parseFloat(formData.productId),
                // countryId: parseFloat(formData.countryId),
                renewableEnergy: parseFloat(formData.renewableEnergy),
                nonRenewableEnergy: parseFloat(formData.nonRenewableEnergy),
                manufacturingUnit: parseFloat(formData.manufacturingUnit),
                refurbished: parseFloat(formData.refurbished),
                disposed: parseFloat(formData.disposed)
            };

            // Handle submission when all steps are completed
            // console.log('Form submitted!', formDataWithNumbers);
            // Redirect or further processing
            GoalsInEveryIndustry(formDataWithNumbers).then(resp => {
                // console.log("data", resp)
                if (resp.status === 200 && resp.data.status.code === 'SUCCESS') {
                    history.push(
                        {
                            pathname: '/calculator',
                            state: { productId: formData.productId, industryId: formData.industry, countryId: formData.countryId }
                        }
                    );
                }
                else {
                    Swal.fire({
                        title: "Oops...",
                        text: resp.data.status.message,
                        icon: "error"
                    })
                }
            })
        }
    };

    const validateStep = () => {
        switch (step) {
            case 1:
                return formData.industry && formData.productId;
            case 2:
                return formData.countryId;
            case 3:
                return formData.renewableEnergy && formData.nonRenewableEnergy;
            case 4:
                return formData.manufacturingUnit;
            case 5:
                return formData.refurbished && formData.disposed;
            default:
                return true;
        }
    };

    const navigate = () => {
        history.push('/calculator');
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const onHandleChangeIndustry = (e) => {
        const { value } = e.target;
        setFormData({ ...formData, industry: value });
        getIndustryTypeList({ industryId: value }).then(res => {
            if (res.status === 200 && res.data.status.code === 'SUCCESS') {
                setIndustryTypeList(res.data.primary);
            }
        });
    };

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <>
                        <h2>Select the Industry</h2>
                        <select className="form-control mt-4" name="industry" style={{ width: '40%' }}
                            onChange={(e) => { onHandleChangeIndustry(e) }}>
                            <option value="">-Select Industry-</option>
                            {industryList.map(item => (
                                <option
                                    key={item.industryId}
                                    value={item.industryId}
                                >
                                    {item.industryName}
                                </option>
                            ))}
                        </select>
                        <br /><br />
                        <h2>Select the Finished Goods</h2>
                        <select className="form-control mt-4" name="productId" style={{ width: '40%' }}
                            onChange={handleChange}>
                            <option value="">-Select Finished Goods-</option>
                            {industryTypeList.map(item => (
                                <option
                                    key={item.productId}
                                    value={item.productId}
                                >
                                    {item.productName}
                                </option>
                            ))}
                        </select>
                    </>
                );
            case 2:
                return (
                    <>
                        <h2>Select the Geography of Manufacturing</h2>
                        <select className="form-control mt-4" name="countryId"
                            style={{ width: '40%' }}
                            value={formData.countryId}
                            onChange={handleChange}>
                            <option value="">-Select-</option>
                            {countryList.map(item => (
                                <option
                                    key={item.countryId}
                                    value={item.countryId}
                                >
                                    {item.countryName}
                                </option>
                            ))}

                        </select>
                    </>
                );
            case 3:
                return (
                    <>
                        <h2>Input the Approximate Energy mix of their Suppliers</h2>
                        <div className="ml-4 mt-3">
                            <strong>Renewable energy (%)</strong>
                            <input type="text" className="form-control mt-2" style={{ width: '50%' }} placeholder="Enter Renewable energy %"
                                name="renewableEnergy" onChange={handleChange} />
                        </div>
                        <div className="ml-4 mt-3">
                            <strong>Non Renewable energy (%)</strong>
                            <input type="text" className="form-control mt-2" style={{ width: '50%' }} placeholder="Enter Non Renewable energy %"
                                name="nonRenewableEnergy" onChange={handleChange} />
                        </div>
                    </>
                );
            case 4:
                return (
                    <>
                        <h2>Input the Manufactured units</h2>
                        <div className="mt-3">
                            <input type="text" className="form-control mt-2" style={{ width: '50%' }} placeholder="Enter Manufactured units"
                                name="manufacturingUnit" onChange={handleChange} />
                        </div>
                    </>
                );
            case 5:
                return (
                    <>
                        <h2>Input the Recycling Methods</h2>
                        <div className="ml-4 mt-3">
                            <strong>Refurbished</strong>
                            <input type="text" className="form-control mt-2" style={{ width: '50%' }} placeholder="Enter Refurbished Units"
                                name="refurbished" onChange={handleChange} />
                        </div>
                        <div className="ml-4 mt-3">
                            <strong>Disposed</strong>
                            <input type="text" className="form-control mt-2" style={{ width: '50%' }} placeholder="Enter Disposed Units"
                                name="disposed" onChange={handleChange} />
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <div>
                <h1 class="m-0" style={{ fontSize: "25px", fontWeight: "600", display: 'inline', textTransform: "none", color: "green" }}>
                    Getting Started
                </h1>
                <p class="ml-1" style={{ fontSize: "18px" }}> You're Only
                    <span style={{ color: "green", fontWeight: "bold", fontSize: "19px" }}> {remainingSteps + 1} </span>
                    steps away from getting started on RealGreen.</p>
            </div>
            <div style={{ marginTop: "7%" }}>
                {renderStep()}
                <div style={{ position: 'fixed', bottom: '20%', right: '5%', textAlign: 'right' }}>
                    {step > 1 && (
                        <button className="btn btn-secondary newBtn mr-3" onClick={() => setStep(step - 1)}>Previous</button>
                    )}
                    {step < totalSteps ? (
                        <button className="btn btn-primary newBtn" onClick={handleNext} disabled={!validateStep()}>Next</button>
                    ) : (
                        <input type="button" value="Continue" onClick={handleNext} className="btn btn-primary newBtn" />
                    )}
                </div>
            </div>
        </>
    );
};

export default PreCheckDetails;
