import React, { useState, useEffect, useRef } from 'react';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { useForm } from "react-hook-form";
import { IoMdAdd, IoMdRemove } from "react-icons/io";
import { realgreenoemOnboard, getActivityList } from "./const";
import ActivityIdentificationList from "./ActivityIdentificationList"
import Swal from 'sweetalert2';

const ActivityIdentification = () => {
    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: 'onChange'
    });
    const [rows, setRows] = useState([{ id: Date.now() }]);
    const [showResults, setShowResults] = useState(false)
    const [resultMessage, setResultMessage] = useState({});


    const [finishedGood, setFinishedGood] = useState('');
    const [component, setComponent] = useState('');
    const [showOpenLCA, setShowOpenLCA] = useState(false);
    const [showClimatiq, setShowClimatiq] = useState(false);
    const [selectedIndustry, setSelectedIndustry] = useState('');
    const [selectFinishedGoodDropdown, setSelectFinishedGoodDropdown] = useState([]);
    const [showSelectFinishedGood, setShowSelectFinishedGood] = useState(false);
    const [componentLevelValue, setcomponentLevelValue] = useState('')
    const [inputList, setInputList] = useState([{ ComponentName: "", activityId: "", params: "", version: "", providerIndex: "" }]);
    const formRef = useRef(null);


    const handleIndustryChange = (event) => {
        setSelectedIndustry(event.target.value)
    }

    const handleFinishedGoodChange = (event) => {
        const value = event.target.value;
        setFinishedGood(value);
        setComponent(value === 'Yes' ? 'No' : 'Yes');
    };

    const handleComponentChange = (event) => {
        const value = event.target.value;
        setComponent(value);
        setFinishedGood(value === 'Yes' ? 'No' : 'Yes');
    };

    const handleDatabaseChange = (event) => {
        const selected = event.target.value;
        if (selected === "openLCA") {
            setShowOpenLCA(true)
            setShowClimatiq(false)
        }
        else if (selected === "climatiq") {
            setShowClimatiq(true)
            setShowOpenLCA(false)
        }
    }

    const handleComponentLevelChange = (event) => {
        setSelectFinishedGoodDropdown([])
        console.log("event", event);
        setcomponentLevelValue(event.target.value)
        let obj = {
            industryType: selectedIndustry,
            componentLevel: event.target.value
        }
        setShowSelectFinishedGood(true)
        getActivityList(obj).then(res => {
            if (res.status === 200 && res.data.status === 'success') {
                setSelectFinishedGoodDropdown(res.data.data);
            }
        })
    }

    const handleAddRow = () => {
        setInputList([...inputList, { ComponentName: "", activityId: "", params: "", version: "", providerIndex: "" }]);
        // console.log("add", inputList)

    };

    const handleRemoveRow = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);

    };


    const handleRest = (e) => {
        formRef.current.reset();
        // e.target.reset();
        setShowOpenLCA(false);
        setShowClimatiq(false);
        setFinishedGood('');
        setComponent('')
        setInputList([{ ComponentName: "", activityId: "", params: "", version: "", providerIndex: "" }]);

    }

    const handleInputChange = async (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        // console.log("e111", list)
        // if (name === 'supplierID') {
        //     // Fetch supplier name when supplierID is changed
        //     const supplierName = await supplierIdDropDownSelect(value);
        //     // console.log("supplierName", supplierName)
        //     list[index]['supplierName'] = supplierName;
        // }
        setInputList(list);
    };

    const onSubmit = (data, e) => {
        let obj = {}
        console.log("data.finishedGoodName", data.FinishedGoodName)
        if (data.ComponentName === undefined) {
            data.ComponentName = ""
        }
        if (data.FinishedGoodName === undefined) {
            data.FinishedGoodName = ""
        }

        if (data.Database === "openLCA") {
            obj = {
                industryType: data.industryType,
                finishedGood: data.FinishedGoodName,
                componentName: data.ComponentName,
                location: data.ManufacturingLocation,
                dataBase: "openLCA",
                dataBaseInput: {
                    productSystemUuid: data.ProductSystemID,
                    impact: data.ImpactUUID,
                    sankey: data.SankeyUUID
                }
            }
        }
        else {
            let formatData;
            let isFinishedGood;
            let isComponent;
            let finishedGoodValue;
            if (finishedGood === "Yes") {
                isFinishedGood = true;
                finishedGoodValue = data.FinishedGoodName;
                formatData = inputList.map((row, index) => ({
                    activityId: row.activityId,
                    params: JSON.parse(row.params),
                    version: row.version,
                    // providerIndex: row.providerIndex
                }));
            }
            else if (finishedGood === "No") {
                finishedGoodValue = data.selectfinishedGood;
                isFinishedGood = false;
                formatData = inputList.map((row, index) => ({
                    componentName: row.ComponentName,
                    activityId: row.activityId,
                    params: JSON.parse(row.params),
                    version: row.version,
                    // providerIndex: row.providerIndex
                }));
            }
            if (component === "Yes") {
                isComponent = true
                obj = {
                    industryType: data.industryType,
                    isFinishedGood: isFinishedGood,
                    finishedGood: finishedGoodValue,
                    isComponent: isComponent,
                    componentLevel: componentLevelValue,
                    // componentName: data.ComponentName,
                    location: data.ManufacturingLocation,
                    dataBase: "climatiq",
                    dataBaseInput: {
                        activityIdArray: formatData
                    }

                }
            }
            else if (component === "No") {
                isComponent = false
                obj = {
                    industryType: data.industryType,
                    isFinishedGood: isFinishedGood,
                    finishedGood: finishedGoodValue,
                    isComponent: isComponent,
                    location: data.ManufacturingLocation,
                    dataBase: "climatiq",
                    dataBaseInput: {
                        activityIdArray: formatData
                    }

                }
            }

        }
        console.log("data", obj);
        realgreenoemOnboard(obj).then(res => {
            if (res.status === 200 && res.data.status === 'success') {
                Swal.fire({
                    title: "Success",
                    text: res.data.message,
                    icon: "success"
                }).then((result) => {
                    if (result.isConfirmed) {
                        e.target.reset();
                        formRef.current.reset();
                        setShowOpenLCA(false);
                        setShowClimatiq(false);
                        setFinishedGood('');
                        setComponent('')
                        setInputList([{ ComponentName: "", activityId: "", params: "", version: "", providerIndex: "" }]);
                    }
                })
            }
        })

    }

    return (
        <>
            <div className="farmMaster">
                <div class="row mb-2">
                    <div class="col-sm-1">
                        <h2 class="m-0 text-dark mt-2" style={{ whiteSpace: "nowrap" }}>Activity Identification</h2>
                    </div>
                </div>
                <div className="card mt-3">
                    <div class="card-body">
                        <div className='row'>
                            <Tabs activeHeaderStyle={{ background: 'transparent' }}>
                                <Tab label="Create Activity ID">
                                    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                                        <div className='row'>
                                            <div className='col-md-9'>
                                                {showResults ? <Results key={Math.random()} message={resultMessage.message} error={resultMessage.error} /> : null}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                <div className="form-group">
                                                    <label htmlFor>Industry Type</label>
                                                    <select
                                                        className="form-control form-control-sm"
                                                        name="industryType"
                                                        {...register("industryType", {
                                                            required: "Please select Industry Type",
                                                            onChange: (e) => { handleIndustryChange(e) }
                                                        })}
                                                    >
                                                        <option value="">-Select-</option>
                                                        <option value="Electronics">Electronics</option>
                                                        <option value="Automotive">Automotive</option>
                                                        <option value="FoodBeverages">FoodBeverages</option>
                                                        <option value="OilGas">OilGas</option>

                                                    </select>
                                                    {errors.industryType && <span className="err-msg">{errors.industryType.message}</span>}
                                                </div>
                                            </div>
                                            <div className='col-md-3'>
                                                <div className="form-group">
                                                    <label htmlFor>Database</label>
                                                    <select
                                                        className="form-control form-control-sm"
                                                        name="Database"
                                                        {...register("Database", {
                                                            required: "Please select Database",
                                                            onChange: (e) => { handleDatabaseChange(e) }
                                                        })}
                                                    >
                                                        <option value="">-Select-</option>
                                                        <option value="openLCA">Open LCA</option>
                                                        <option value="climatiq">Climatiq</option>
                                                    </select>
                                                    {errors.Database && <span className="err-msg">{errors.Database.message}</span>}
                                                </div>
                                            </div>
                                            {showClimatiq &&
                                                <div className='col-md-2'>
                                                    <div className="form-group ml-3">
                                                        <label>Finished Good</label>
                                                        <br></br>
                                                        <input
                                                            className='ml-4 mt-2'
                                                            type="radio"
                                                            value="Yes"
                                                            name="finishedGood"
                                                            checked={finishedGood === 'Yes'}
                                                            onChange={handleFinishedGoodChange}
                                                        /> Yes
                                                        <input
                                                            className='ml-5 mt-2'
                                                            type="radio"
                                                            value="No"
                                                            name="finishedGood"
                                                            checked={finishedGood === 'No'}
                                                            onChange={handleFinishedGoodChange}
                                                        /> No
                                                        {errors.finishedGood && <span className="err-msg">{errors.finishedGood.message}</span>}
                                                    </div>
                                                </div>
                                            }
                                            {
                                                showOpenLCA &&

                                                <div className='col-md-3'>
                                                    <div className="form-group">
                                                        <label htmlFor>Finished Good Name</label>
                                                        <input type="text"
                                                            className="form-control form-control-sm"
                                                            placeholder='Enter Finished Good Name'
                                                            name="FinishedGoodName"
                                                            {...register("FinishedGoodName", {
                                                                required: "Please Enter",
                                                                // onChange: (e) => { handleMaterialNameChange(e) }
                                                            })}
                                                        />
                                                        {errors.FinishedGoodName && <span className="err-msg">{errors.FinishedGoodName.message}</span>}
                                                    </div>
                                                </div>
                                            }
                                            {
                                                finishedGood === 'Yes' &&
                                                <div className='col-md-3'>
                                                    <div className="form-group">
                                                        <label htmlFor>Finished Good Name</label>
                                                        <input type="text"
                                                            className="form-control form-control-sm"
                                                            placeholder='Enter Finished Good Name'
                                                            name="FinishedGoodName"
                                                            {...register("FinishedGoodName", {
                                                                required: "Please Enter",
                                                                // onChange: (e) => { handleMaterialNameChange(e) }
                                                            })}
                                                        />
                                                        {errors.FinishedGoodName && <span className="err-msg">{errors.FinishedGoodName.message}</span>}
                                                    </div>
                                                </div>
                                            }
                                            {
                                                finishedGood === 'No' &&
                                                <div className='col-md-2'>
                                                    <div className="form-group">
                                                        <label htmlFor>Component</label>
                                                        <br></br>
                                                        <input
                                                            className='ml-4 mt-2'
                                                            type="radio"
                                                            value="Yes"
                                                            name="Component"
                                                            checked={component === 'Yes'}
                                                            onChange={handleComponentChange}
                                                        /> Yes
                                                        <input
                                                            className='ml-5 mt-2'
                                                            type="radio"
                                                            value="No"
                                                            name="Component"
                                                            checked={component === 'No'}
                                                            onChange={handleComponentChange}
                                                        /> No
                                                        {errors.Component && <span className="err-msg">{errors.Component.message}</span>}
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                        <div className='row'>
                                            {
                                                component === 'Yes' &&
                                                <div className='col-md-3'>
                                                    <div className="form-group">
                                                        <label htmlFor>Component Level</label>
                                                        <select
                                                            className="form-control form-control-sm"
                                                            name="componentLevel"
                                                            {...register("componentLevel", {
                                                                required: "Please select Activity Type",
                                                                onChange: (e) => { handleComponentLevelChange(e) }
                                                            })}
                                                        >
                                                            <option value="">-Select-</option>
                                                            <option value="1">Tier1</option>
                                                            <option value="2">Tier2</option>
                                                        </select>
                                                        {errors.componentLevel && <span className="err-msg">{errors.componentLevel.message}</span>}
                                                    </div>
                                                </div>
                                            }
                                            {showClimatiq &&
                                                <>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor>Manufacturing Location</label>
                                                            <input type="text"
                                                                className="form-control form-control-sm"
                                                                placeholder='Enter Manufacturing Location'
                                                                name="ManufacturingLocation"
                                                                {...register("ManufacturingLocation", {
                                                                    required: "Please Enter",
                                                                    // onChange: (e) => { handleMaterialNameChange(e) }
                                                                })}
                                                            />
                                                            {errors.ManufacturingLocation && <span className="err-msg">{errors.ManufacturingLocation.message}</span>}
                                                        </div>
                                                    </div>
                                                    {
                                                        showSelectFinishedGood &&
                                                        <div className='col-md-3'>
                                                            <div className="form-group">
                                                                <label htmlFor>Select Finished Good</label>
                                                                <select
                                                                    className="form-control form-control-sm"
                                                                    name="selectfinishedGood"
                                                                    {...register("selectfinishedGood", {
                                                                        required: "Please select Finished Good",
                                                                    })}
                                                                >

                                                                    <option value="">-Select-</option>
                                                                    {selectFinishedGoodDropdown.map(item => (
                                                                        <option
                                                                            key={item.activityId}
                                                                            value={item.productName}
                                                                        >
                                                                            {item.productName} - {item.activityId}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                {errors.selectfinishedGood && <span className="err-msg">{errors.selectfinishedGood.message}</span>}
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            }


                                        </div>
                                        <br></br>
                                        <div className='row'>
                                            {
                                                showOpenLCA && (
                                                    <>
                                                        <div className='col-md-3'>
                                                            <div className="form-group">
                                                                <label htmlFor>Manufacturing Location</label>
                                                                <input type="text"
                                                                    className="form-control form-control-sm"
                                                                    placeholder='Enter Manufacturing Location'
                                                                    name="ManufacturingLocation"
                                                                    {...register("ManufacturingLocation", {
                                                                        required: "Please select",
                                                                        // onChange: (e) => { handleMaterialNameChange(e) }
                                                                    })}
                                                                />
                                                                {errors.ManufacturingLocation && <span className="err-msg">{errors.ManufacturingLocation.message}</span>}
                                                            </div>
                                                        </div>
                                                        <div className='col-md-3'>
                                                            <div className="form-group">
                                                                <label htmlFor>Product System UUID</label>
                                                                <input type="text"
                                                                    className="form-control form-control-sm"
                                                                    placeholder='Enter Product System UUID'
                                                                    name="ProductSystemID"
                                                                    {...register("ProductSystemID", {
                                                                        required: "Please select",
                                                                        // onChange: (e) => { handleMaterialNameChange(e) }
                                                                    })}
                                                                />
                                                                {errors.ProductSystemID && <span className="err-msg">{errors.ProductSystemID.message}</span>}
                                                            </div>
                                                        </div>
                                                        <div className='col-md-3'>
                                                            <div className="form-group">
                                                                <label htmlFor>Impact UUID</label>
                                                                <input type="text"
                                                                    className="form-control form-control-sm"
                                                                    placeholder='Enter Impact UUID'
                                                                    name="ImpactUUID"
                                                                    {...register("ImpactUUID", {
                                                                        required: "Please select",
                                                                        // onChange: (e) => { handleMaterialNameChange(e) }
                                                                    })}
                                                                />
                                                                {errors.ImpactUUID && <span className="err-msg">{errors.ImpactUUID.message}</span>}
                                                            </div>
                                                        </div>
                                                        <div className='col-md-3'>
                                                            <div className="form-group">
                                                                <label htmlFor>Sankey UUID</label>
                                                                <input type="text"
                                                                    className="form-control form-control-sm"
                                                                    placeholder='Enter Sankey UUID'
                                                                    name="SankeyUUID"
                                                                    {...register("SankeyUUID", {
                                                                        required: "Please select",
                                                                        // onChange: (e) => { handleMaterialNameChange(e) }
                                                                    })}
                                                                />
                                                                {errors.SankeyUUID && <span className="err-msg">{errors.SankeyUUID.message}</span>}
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </div>
                                        {showClimatiq &&
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <strong>Information</strong>
                                                </div>
                                                {inputList.map((row, index) => (
                                                    <div className='row col-md-12'>
                                                        <div className='row m-3' key={index}>
                                                            {
                                                                component === 'Yes' &&
                                                                <>
                                                                    <div className='col'>
                                                                        <div className="form-group">
                                                                            <label htmlFor>Component Name</label>
                                                                            <input
                                                                                className="form-control form-control-s"
                                                                                name="ComponentName"
                                                                                placeholder="Enter Activity Id"
                                                                                value={row.ComponentName}
                                                                                onChange={(e) => handleInputChange(e, index)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            <div className='col'>
                                                                <div className="form-group">
                                                                    <label>Activity ID</label>
                                                                    <input
                                                                        className="form-control form-control-s"
                                                                        name="activityId"
                                                                        placeholder="Enter Activity Id"
                                                                        value={row.activityId}
                                                                        onChange={(e) => handleInputChange(e, index)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col'>
                                                                <div className="form-group">
                                                                    <label>Params</label>
                                                                    <textarea
                                                                        className="form-control form-control-s"
                                                                        type="textarea"
                                                                        name="params"
                                                                        placeholder="Enter Params"
                                                                        value={row.params}
                                                                        onChange={(e) => handleInputChange(e, index)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col'>
                                                                <div className="form-group">
                                                                    <label>Version</label>
                                                                    <input
                                                                        className="form-control form-control-s"
                                                                        name="version"
                                                                        placeholder="Enter Version"
                                                                        value={row.version}
                                                                        onChange={(e) => handleInputChange(e, index)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {/* <div className='col-md-2'>
                                                                <div className="form-group">
                                                                    <label>Linked To</label>
                                                                    <input
                                                                        className="form-control form-control-s"
                                                                        name="providerIndex"
                                                                        placeholder="Enter Linked To"
                                                                        value={row.providerIndex}
                                                                        onChange={(e) => handleInputChange(e, index)}
                                                                    />
                                                                </div>
                                                            </div> */}
                                                            {finishedGood === 'No' &&
                                                                <>
                                                                    {
                                                                        inputList.length - 1 === index && (

                                                                            <div className='col-md-1 mt-4' id="addMore" style={{ whiteSpace: 'nowrap' }}>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-block btn-outline-success btn-sm"
                                                                                    onClick={handleAddRow}
                                                                                >
                                                                                    <IoMdAdd /> Add
                                                                                </button>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    {inputList.length !== 1 && (

                                                                        <div className='col-md-1 mt-4' id="addMore" style={{ whiteSpace: 'nowrap' }}>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-block btn-outline-danger btn-sm"
                                                                                onClick={() => handleRemoveRow(index)}
                                                                                style={{ width: "90px", whiteSpace: "nowrap" }}
                                                                            >
                                                                                <IoMdRemove /> Remove
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <input type="submit" value="Submit" class="btn btn-primary newBtn" />
                                                <input onClick={() => handleRest()} type="reset" value="Reset" class="btn btn-secondary newBtn" />
                                            </div>
                                        </div>
                                    </form>
                                </Tab>
                                <Tab label="Activity ID List">
                                    <ActivityIdentificationList></ActivityIdentificationList>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const Results = (props) => {
    let error, classes;
    if (props.error === false) {
        error = 'Success';
        classes = 'alert alert-success alert-dismissible fade show'
    } else {
        error = 'Error!';
        classes = 'alert alert-danger alert-dismissible fade show'
    }
    return (
        <div className="results">
            <div className={classes}>
                <strong>{error}</strong> {props.message}
                <button type="button" class="close" data-dismiss="alert">&times;</button>
            </div>
        </div>
    );
}

export default ActivityIdentification;