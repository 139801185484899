import { useState } from "react";
import { Tabs, Tab } from 'react-bootstrap-tabs';
import ExcelUploader from "../excelUpload/excelUpload";
import BOQUploadsPreview from "./BOQUploadsPreview";
import CarbonEmissionUploadsPreview from "./CarbonEmissionUploadsPreview";
import WaterConsumptionUploadsPreview from "./WaterConsumptionUploadsPreview"
import Documents from "./Documents"
const Uploads = () => {
    const [showPreviewTable, setShowPreviewTable] = useState(false);
    const [data, setData] = useState([]);
    const [tabKey, setTabKey] = useState(0); // State to keep track of the tab key

    const handleTabChange = (newKey) => {
        setTabKey(newKey); // Update the tab key when the tab changes
        setShowPreviewTable(false);
        setData([]);
    };
    const responseData = (data) => {
        if (data.status.code === "SUCCESS") {
            setData(data.primary.data);
            setShowPreviewTable(true);
        }
    }
    return (
        <div className="farmMaster">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">Uploads</h2>
                </div>
            </div>
            <div className="card">
                <div class="card-body">
                    <Tabs activeHeaderStyle={{ background: 'transparent' }} onSelect={() => handleTabChange(Date.now())}>
                        <Tab label="Upload BOQ" eventKey="Upload BOQ">
                            <div className="row">
                                <div className="col">
                                    <ExcelUploader key={tabKey} uploadType='BOQ' filePath='/BOQ.xlsx' responseDataSend={responseData}></ExcelUploader>
                                </div>
                            </div>
                            {showPreviewTable && (<BOQUploadsPreview tableData={data}></BOQUploadsPreview>)}
                        </Tab>
                        {/* <Tab label="Upload Carbon Emission" eventKey="Upload Carbon Emission" disabled>
                            <div className="row">
                                <div className="col">
                                    <ExcelUploader uploadType="Dispatch Info" filePath='/CarbonEmission.xlsx'></ExcelUploader>
                                </div>
                            </div>
                        </Tab> */}
                        <Tab label="Upload Carbon Emission" eventKey="Upload Carbon Emission">
                            <div className="row">
                                <div className="col">
                                    <ExcelUploader key={tabKey} uploadType="Carbon Emission" filePath='/CarbonEmission.xlsx' responseDataSend={responseData}></ExcelUploader>
                                </div>
                            </div>
                            {showPreviewTable && (<CarbonEmissionUploadsPreview tableData={data}></CarbonEmissionUploadsPreview>)}
                        </Tab>
                        <Tab label="Upload Water Consumption" eventKey="Upload Water Consumption">
                            <div className="row">
                                <div className="col">
                                    <ExcelUploader key={tabKey} uploadType="Water Consumption" filePath='/WaterConsumption.xlsx' responseDataSend={responseData}></ExcelUploader>
                                </div>
                            </div>
                            {showPreviewTable && (<WaterConsumptionUploadsPreview tableData={data}></WaterConsumptionUploadsPreview>)}
                        </Tab>
                        <Tab label="Upload Documents" eventKey="Upload Documents">
                            <div className="row">
                                <div className="col">
                                    <Documents></Documents>
                                    {/* <ExcelUploader uploadType="Dispatch Info" filePath='/CarbonEmission.xlsx'></ExcelUploader> */}
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

export default Uploads;