import React from 'react';
import ReactEcharts from 'echarts-for-react';

const ComponentWiseLineChart = ({ topComponentsData }) => {
    let options = {
        title: {
            //text: 'Inventory Status',
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: ['BaseLine', 'Goal', 'Actual'],
            orient: 'horizontal', // This is optional as 'horizontal' is the default value
            top: '0%',
        },
        grid: {
            left: 20,
            top: 25,
            right: 30,
            bottom: 30,
            containLabel: true
        },
        toolbox: {

        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            name: 'Components',
            nameLocation: 'middle',
            data: topComponentsData.xData,
            axisTick: {
                alignWithLabel: true
            },
            axisPointer: {
                type: 'shadow'
            },
            nameLocation: 'middle',
            nameGap: 30
        },

        yAxis: {
            type: 'value',
            name: 'kg CO₂ e',
            nameLocation: 'middle',
            nameGap: 37,
            axisLabel: {
                formatter: '{value}'
            },
            min: 0,

        },
        series: [
            {
                name: 'BaseLine',
                type: 'line',
                data: topComponentsData.baseData,
                smooth: true, // Make line smooth
                itemStyle: {
                    color: 'green',
                },
                lineStyle: {
                    type: 'solid'
                }
            },
            {
                name: 'Goal',
                type: 'line',
                smooth: true, // Make line smooth
                data: topComponentsData.goalData,
                itemStyle: {
                    color: 'red',
                },
                lineStyle: {
                    type: 'dotted'
                }
            },
            {
                name: 'Actual',
                type: 'line',
                smooth: true, // Make line smooth
                data: topComponentsData.actualData,
                itemStyle: {
                    color: 'blue',
                },
                lineStyle: {
                    type: 'dashed'
                }
            }
        ]

    };

    return (
        <div className='mt-3' style={{ height: '300px' }}>
            <ReactEcharts
                option={options}
                style={{ height: '100%', width: '100%' }}
                opts={{ renderer: 'svg' }}
            />
        </div>
    );
}

export default ComponentWiseLineChart;