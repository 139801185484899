import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { everyComponentWiseCo2eComposition } from "../const"

const PieChart = ({ id, mainComp, enteredManufacturedUnit, sendDataToParent, selectedDropdownValue }) => {
    // console.log("activeID", id);
    const [data, setData] = useState([]);

    useEffect(() => {
        let componentName;
        if (id !== "") {
            if (id === mainComp) {
                componentName = ""
            } else {
                componentName = id
            }

            let obj
            if (enteredManufacturedUnit) {
                obj = {
                    "productName": mainComp,
                    "componentName": componentName,
                    "manufacturingUnit": enteredManufacturedUnit,
                    "consumptionType": selectedDropdownValue
                }
            }
            else {
                obj = {
                    "productName": mainComp,
                    "componentName": componentName,
                    "consumptionType": selectedDropdownValue
                }
            }
            everyComponentWiseCo2eComposition(obj).then(resp => {
                if (resp.status === 200 && resp.data.status.code === 'SUCCESS') {
                    let array = resp.data.primary.map((item, index) => ({
                        name: item.componentName,
                        value: `${item.componentValue.toFixed(2)}`
                    }))
                    setData(array);
                }
            });
        }

    }, [id, selectedDropdownValue])

    // Function to handle click events on pie chart sectors
    const handleClick = (params) => {
        if (id === mainComp) {
            console.log("clicked111")
            if (params.data) {
                console.log("clicked", params.data)

                let componentName = params.data.name
                sendDataToParent({ name: params.data.name });

                let obj
                if (enteredManufacturedUnit) {
                    obj = {
                        "productName": mainComp,
                        "componentName": componentName,
                        "manufacturingUnit": enteredManufacturedUnit
                    }
                }
                else {
                    obj = {
                        "productName": mainComp,
                        "componentName": componentName
                    }
                }
                everyComponentWiseCo2eComposition(obj).then(resp => {
                    if (resp.status === 200 && resp.data.status.code === 'SUCCESS') {
                        let array = resp.data.primary.map((item, index) => ({
                            name: item.componentName,
                            value: `${item.componentValue.toFixed(2)}`
                        }))
                        setData(array);
                    }
                });

            }
        }

    };

    const onChartClick = () => {

    }

    // ECharts options for the pie chart
    const options =
    {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            // orient: 'horizontal',
            // left: 'center',
            // bottom: '-10%',
            show: false
        },
        series: [
            {
                type: 'pie',
                radius: ['40%', '70%'],
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                // label: {
                //   show: false,
                //   position: 'center'
                // },
                // emphasis: {
                //   label: {
                //     show: true,
                //     fontSize: 20,
                //     fontWeight: 'bold'
                //   }
                // },
                // labelLine: {
                //   show: true
                // },
                data: data,
                emphasis: {
                    scale: true,
                    scaleSize: 10,
                    itemStyle: {
                        shadowOffsetX: 0,
                        shadowOffsetY: 0,
                        shadowBlur: 10,
                        opacity: 0.7
                    }
                },
                // selectedMode: 'single',
                events: {
                    click: (params) => {
                        this.onChartClick(params);
                    }
                }
            }
        ]
    };

    return (
        <div style={{ height: '400px' }}>
            <ReactEcharts
                option={options}
                style={{ height: '100%', width: '100%' }}
                opts={{ renderer: 'svg' }}
                onEvents={{
                    'click': handleClick // Call handleClick function on click event
                }}
            />
        </div>
    );
};

export default PieChart;
