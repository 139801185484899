import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
const SupplierInfo = ({ showPopup, materialID, closePopup, supplierData }) => {
    const columns = [
        {
            name: "supplierId",
            selector: row => row.supplierId,
            sortable: true,
        },
        {
            name: "supplierName",
            selector: row => row.supplierName,
            sortable: true,
        },

    ];
    const [data, setData] = useState(supplierData);
    return (
        <Modal show={showPopup} onHide={closePopup} size='xl'>
            <Modal.Header>
                <Modal.Title>Material - {materialID}, Material Info</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12">
                        <DataTable
                            columns={columns}
                            data={data}
                            defaultSortField="supplierId"
                            pagination
                            dense
                            selectableRowsHighlight='true'
                            compact
                            highlightOnHover='true'
                            striped
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closePopup}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SupplierInfo;
