import DataTable from "react-data-table-component";
import React, { useState, useEffect } from 'react';
import { boqListInOEM } from "../const";
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';

const OEMBOQList = () => {
    const columns = [
        {
            name: "SERIAL NUMBER",
            selector: row => row.serialNumber,
            sortable: true,
        },
        {
            name: "COMPONENT NAME",
            selector: row => row.componentName,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "MATERIAL COMPONENT NAME",
            selector: row => row.materialComponentName,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "QUANTITY",
            selector: row => row.quantity,
            sortable: true,
        },
        {
            name: "UNIT",
            selector: row => row.unit,
            sortable: true,
        },
        {
            name: "VERSION NUMBER",
            selector: row => row.versionNumber,
            sortable: true,
        }
    ];
    const [data, setData] = useState([]);

    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="22" color="#333" />,
    });
    const [showLoader, setShowLoader] = useState(false);


    useEffect(() => {
        setShowLoader(true);
        boqListInOEM().then(res => {
            if (res.status === 200 && res.data.status.code === 'SUCCESS') {
                setData(res.data.primary);
                setShowLoader(false);
            }
        })
    }, [])

    return (
        <>
            <DataTable
                columns={columns}
                data={data}
                //defaultSortField="templateID"
                pagination
                dense
                selectableRowsHighlight='true'
                compact
                highlightOnHover='true'
            />
            {
                showLoader ?
                    <section {...containerProps
                    } style={{ "margin-top": "0px", display: 'inline' }}>
                        {indicatorEl}
                    </section > : ""
            }
        </>
    )

}

export default OEMBOQList;